import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { DataAvailabilityInfo } from '../types/data-availability-info.type';

@Injectable({ providedIn: 'root' })
export class DataAvailabilityService {
  private _cache = new Map<string, DataAvailabilityInfo>();

  constructor(private apiService: ApiService) {}

  async getDataAvailability(processGroupId?: string): Promise<DataAvailabilityInfo> {
    let dataAvailabilityInfo = this._cache.get(processGroupId);
    if (dataAvailabilityInfo == null) {
      try {
        const datesWithData = await this.apiService.monitoring.getDatesWithData(processGroupId);
        // if datesWithData is empty it means that there were no connection (Blue Prism | UiPath) established yet and for informing the user we need to set isValid to false
        dataAvailabilityInfo = new DataAvailabilityInfo(datesWithData, datesWithData != null && datesWithData.length > 0);
        this._cache.set(processGroupId, dataAvailabilityInfo);
      } catch (error) {
        return null;
      }
    }

    return dataAvailabilityInfo;
  }

  clear() {
    this._cache.clear();
  }
}
