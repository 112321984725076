import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { SetPasswordFormComponent } from 'src/app/modules/login/set-password-form/set-password-form.component';
import { BaseModalDialogDirective } from '../../../directives/base-modal-dialog.directive';
import { ModalSize } from '../../../enums/modal-size.enum';
import { ModalCloseType } from '../../modal/modal-close-type.enum';

@Component({
  selector: 'shared-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent extends BaseModalDialogDirective implements OnInit {
  @ViewChild('componentPlaceholder', { read: ViewContainerRef, static: true }) public componentPlaceholder: ViewContainerRef;

  get closeType(): ModalCloseType {
    return ModalCloseType.Safe;
  }

  get size(): ModalSize {
    return ModalSize.Small;
  }

  private _setPasswordForm: SetPasswordFormComponent;

  ngOnInit(): void {
    void this.initialize();
  }

  async initialize(): Promise<void> {
    const module = await import('src/app/modules/login/login.module');
    const component = module.LoginModule.getSetPasswordFormComponent();
    this._setPasswordForm = this.componentPlaceholder.createComponent(component).instance;
    this._setPasswordForm.passwordChange.subscribe(() => this.close());
  }
}
