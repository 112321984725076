import { Pipe, PipeTransform } from '@angular/core';
import { FormatType } from '../enums/format-type.enum';
import { FormatHelper } from '../helpers/format.helper';

@Pipe({ name: 'format' })
export class FormatPipe implements PipeTransform {
  transform(value: number | string | Date, type: FormatType, addSign = false, tooltip = false, postfix = ''): string {
    return FormatHelper.format(value, type, addSign, tooltip, postfix);
  }
}
