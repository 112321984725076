import { Injectable } from '@angular/core';
import { ApiConversionHelper } from '../../helpers/api-conversion.helper';
import { DateHelper } from '../../helpers/date.helper';
import { ExceptionFilter } from '../../types/exception-filter.type';
import { ExceptionSort } from '../../types/exception-sort.type';
import { ManagementMetricsResult } from '../../types/management-metrics-result.type';
import { TaskException } from '../../types/task-exception';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class ManagementMetricsApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/management-metrics`;
  }

  async getManagementMetrics(fromDate: Date, toDate: Date, domainId?: string, processGroupId?: string): Promise<ManagementMetricsResult> {
    const params = {
      fromDate: `${DateHelper.formatApiDateOnly(fromDate)}`,
      toDate: `${DateHelper.formatApiDateOnly(toDate)}`,
      processGroupId,
      domainId,
    };

    const result = await this.get<ManagementMetricsResult>('', params);
    ApiConversionHelper.convertDates(result.aggregated, ['fromDate', 'toDate']);
    ApiConversionHelper.convertDates(result.history, ['fromDates', 'toDates']);
    return result;
  }

  async getTopExceptions(fromDate: Date, toDate: Date, pageSize: number, pageIndex: number, filter: ExceptionFilter, sort: ExceptionSort): Promise<TaskException[]> {
    const params = {
      fromDate: `${DateHelper.formatApiDateOnly(fromDate)}`,
      toDate: `${DateHelper.formatApiDateOnly(toDate)}`,
      pageSize,
      pageIndex,
      ...filter,
      ...sort,
    };

    return await this.get<TaskException[]>('top-exceptions', params);
  }
}
