import { Injectable } from '@angular/core';
import { Observable, Subject, map, tap } from 'rxjs';
import { Data } from '../types/data.type';
import { FlameGraphNode } from '../types/flame-graph-data.type';
import { ProcessBasicInfo } from '../types/process-basic-Info.type';
import { Variant } from '../types/variant.type';
import { ApiService } from './api/api.service';
import { LoadingScreenService } from './loading-screen.service';
import { PopupService } from './popup.service';
import { ProcessViewService } from './process-view.service';
import { StorageService } from './storage.service';
import { UploadProcessingService } from './upload-processing.service';
import { VariantsSelectionService } from './variants-selection.service';

@Injectable({ providedIn: 'root' })
export class SelectionService {
  get selectedProcess(): ProcessBasicInfo {
    return this._selectedProcess;
  }

  get selectedMasterProcess(): ProcessBasicInfo {
    return this._selectedMasterProcess;
  }

  get masterProcesses$(): Observable<ProcessBasicInfo[]> {
    return this.masterProcessesSubject.asObservable();
  }

  masterProcesses: ProcessBasicInfo[];
  processesMap = new Map<string, ProcessBasicInfo>();

  private _selectedProcess: ProcessBasicInfo;
  private _selectedMasterProcess: ProcessBasicInfo;
  private newProcessesMap = new Map<string, boolean>();
  private masterProcessesSubject = new Subject<ProcessBasicInfo[]>();

  constructor(
    private data: Data,
    private apiService: ApiService,
    private processViewService: ProcessViewService,
    private storageService: StorageService,
    private variantSelectionService: VariantsSelectionService,
    private uploadProcessingService: UploadProcessingService,
    private popupService: PopupService,
    private loadingScreenService: LoadingScreenService,
  ) {
    this.uploadProcessingService.items$
      .pipe(
        map(items => items.filter(i => i.isSuccessful)),
        tap(items => {
          for (const masterProcessId of items.flatMap(i => i.masterProcessIds)) {
            if (!this.newProcessesMap.has(masterProcessId)) {
              this.newProcessesMap.set(masterProcessId, true);
            }
          }
        }),
      )
      .subscribe();
  }

  clear(): void {
    this._selectedMasterProcess = null;
    this._selectedProcess = null;
    this.masterProcesses = null;
    this.processesMap.clear();
    this.data.masterProcess = null;
  }

  // Processes
  async initializeMasterProcessesIfNeeded(): Promise<void> {
    if (!this.masterProcesses) {
      await this.initializeMasterProcesses(null, false);
    }
  }

  async initializeMasterProcesses(monitoringProcessGroupId?: string, shouldSelectStoredProcess = true): Promise<void> {
    this.clear();
    this.loadingScreenService.showLoadingScreen();
    try {
      this.masterProcesses = await this.apiService.getMasterProcesses(monitoringProcessGroupId);
      this.masterProcesses.forEach(m => {
        m.isNew = this.newProcessesMap.get(m.masterProcessId) === true;
      });
    } catch (error) {
      this.data.canNotBeLoaded = true;
      this.masterProcesses = null;
      this.loadingScreenService.hideLoadingScreen();
    }
    this.initializeProcesses(this.masterProcesses, null);
    if (shouldSelectStoredProcess && this.masterProcesses?.length > 0 && this.storageService.selectedProcessId) {
      await this.selectProcessById(this.storageService.selectedProcessId);
    }

    this.loadingScreenService.hideLoadingScreen();
    this.masterProcessesSubject.next(this.masterProcesses);
    this.data.loaded = true;
  }

  private initializeProcesses(processes: ProcessBasicInfo[], parent: ProcessBasicInfo): void {
    processes?.forEach(p => {
      this.processesMap.set(p.id, p);
      if (parent) {
        p.parent = parent;
      }
      if (p.children) {
        this.initializeProcesses(p.children, p);
      }
    });
  }

  async initializeMasterProcess(): Promise<void> {
    if (this.selectedMasterProcess?.masterProcessId) {
      await this.data.initializeMasterProcess(this.selectedMasterProcess.masterProcessId);
      this.initializeFlameGraph(this.data.masterProcess.flameGraphRoot);
    }
  }

  private initializeFlameGraph(root: FlameGraphNode) {
    root.uniqueProcessId = this.selectedMasterProcess.id;
    this.initializeFlameGraphNodes(root.children, root.uniqueProcessId);
  }

  private initializeFlameGraphNodes(children: FlameGraphNode[], parentUniqueProcessId: string) {
    if (children == null) {
      return;
    }
    children.forEach(c => {
      if (c.itemId == null) {
        c.uniqueProcessId = this.getChildUniqueProcessId(parentUniqueProcessId, c.processId);
        this.initializeFlameGraphNodes(c.children, c.uniqueProcessId);
      } else {
        c.uniqueProcessId = parentUniqueProcessId;
      }
    });
  }

  async initializeMasterProcessUserData(): Promise<void> {
    if (this.selectedMasterProcess) {
      await this.data.initializeMasterProcessUserData(this.selectedMasterProcess.masterProcessId);
    }
  }

  getMasterProcess(masterProcessId: string): ProcessBasicInfo {
    return this.masterProcesses.find(p => p.masterProcessId === masterProcessId);
  }

  async selectProcessById(id: string, variantIds?: Set<string> | 'all'): Promise<void> {
    if (!id) {
      return;
    }
    await this.initializeMasterProcessesIfNeeded();
    if (this.masterProcesses) {
      if (!this.processesMap.has(id)) {
        throw new Error(`master process ${id} was not found`);
      }
      const masterProcess = this.processesMap.get(id);
      await this.selectProcess(masterProcess, variantIds);
    }
  }

  async deleteMasterProcess(masterProcess: ProcessBasicInfo): Promise<boolean> {
    const result = await this.popupService.showOkCancelQuestion('Delete analysis?', 'Are you sure you want to delete this analysis?\nThis action cannot be undone.', 'Delete');
    if (!result) {
      return false;
    }

    try {
      await this.apiService.deleteMasterProcess(masterProcess.masterProcessId);
      this.storageService.selectedProcessId = null;
      this.popupService.showSnackBar(`Analysis ${masterProcess.label} has been deleted.`);
      return true;
    } catch (error) {
      await this.popupService.showInfo('', 'We could not delete the analysis.');
      return false;
    }
  }

  private async selectProcess(process: ProcessBasicInfo, variantIds?: Set<string> | 'all'): Promise<void> {
    if (this.selectedProcess === process) {
      return;
    }

    this._selectedProcess = process;
    this.storageService.selectedProcessId = process.id;

    await this.selectMasterProcess();
    await this.initializeProcess(variantIds);
  }

  private async selectMasterProcess() {
    if (!this.selectedProcess) {
      return;
    }

    // process.isNew = false;
    if (this.newProcessesMap.has(this.selectedProcess.masterProcessId)) {
      this.newProcessesMap.set(this.selectedProcess.masterProcessId, false);
    }
    let selectedMasterProcess = this._selectedProcess;
    while (!selectedMasterProcess.masterProcessId && selectedMasterProcess.parent) {
      selectedMasterProcess = selectedMasterProcess.parent;
    }

    if (selectedMasterProcess.id !== this._selectedMasterProcess?.id) {
      this._selectedMasterProcess = selectedMasterProcess;
      this.storageService.selectedMasterProcessId = selectedMasterProcess?.masterProcessId;
      await this.initializeMasterProcess();
    }
  }

  private async initializeProcess(variantIds?: Set<string> | 'all'): Promise<void> {
    if (this.selectedProcess) {
      await this.data.initializeProcess(this.selectedProcess);
      const predicate: (v: Variant) => boolean = variantIds == null ? null : variantIds === 'all' ? () => true : v => variantIds.has(v.id);
      await this.variantSelectionService.initialize(predicate);
      this.processViewService.initialize();
    }
  }

  public getChildUniqueProcessId(parentUniqueProcessId: string, childProcessId: string): string {
    const parent = this.processesMap.get(parentUniqueProcessId);
    const child = parent.children.find(c => c.processId === childProcessId);
    if (child == null) {
      throw new Error(`Process not found (processId = ${childProcessId})`);
    }
    return child.id;
  }
}
