<shared-modal [modalTitle]="'Jump to...'">
  <div class="md-float-material form-material" modalBody>
    <app-search-input (search)="onSearchChanged($event)" (clear)="onSearchInputClear($event)" [searchValue]="searchValue" [placeholder]="'Search for pages or nested processes'"></app-search-input>

    <div class="process-tree-content" *ngIf="treeControl && treeControl.dataNodes[0]">
      <div class="empty-label">
        <span class="tree-node-label disabled" *ngIf="!treeControl.dataNodes[0].visible">We couldn’t find a process or a group with that name</span>
      </div>

      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="process-tree" *ngIf="dataSource">
        <!-- tree end nodes-->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="8" [ngClass]="{ hidden: !node.visible }">
          <div class="mat-tree-node tree-leaf" [ngClass]="{ selected: isSelected(node), 'not-found': !node.found }" (click)="setProcess(node)">
            <a [routerLink]="getUrl(node.process.id)" class="tree-node-label">{{ node.process.label }}</a>
          </div>
        </mat-tree-node>
        <!-- end of tree end nodes -->

        <!-- tree root nodes-->
        <mat-tree-node *matTreeNodeDef="let node; when: expandable" matTreeNodePadding matTreeNodePaddingIndent="8" [ngClass]="{ hidden: !node.visible }">
          <div class="mat-tree-node" [ngClass]="{ selected: isSelected(node), 'not-found': !node.found }" (click)="setProcess(node)">
            <a [routerLink]="getUrl(node.process.id)" *ngIf="node.process.masterProcessId" class="tree-node-label root">{{'main-page' | platformTexts}}</a>
            <a [routerLink]="getUrl(node.process.id)" *ngIf="!node.process.masterProcessId" class="tree-node-label">{{ node.process.label }}</a>
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
              <svg-icon key="arrow-button-down" *ngIf="!treeControl.isExpanded(node)" class="toggle-icon"></svg-icon>
              <svg-icon key="arrow-button-up" *ngIf="treeControl.isExpanded(node)" class="toggle-icon"></svg-icon>
            </button>
          </div>
        </mat-tree-node>
        <!-- end of tree root nodes -->
      </mat-tree>
    </div>
  </div>
</shared-modal>
