<ng-container *ngIf="(notificationService.notificationBarMessages$ | async ) as alertMsgs">
  <div *ngIf="alertMsgs && alertMsgs.length > 0" class="alert-bar">
    <ng-container *ngFor="let msg of alertMsgs; let i=index">
      <div class="alert-bar__message" [ngClass]="msg.type">
        <svg-icon [key]="AlertType.Warning"></svg-icon>
        <p [innerHTML]="msg.htmlText"></p>
        <button type="button" *ngIf="msg.contactSupportEnabled" (click)="openContactSupportModal()" class="btn btn-link">Contact support</button>
        <button type="button" *ngIf="msg.link" (click)="navigateByRoute(msg.link.route)" class="btn btn-link">{{msg.link.text}}</button>
        <!-- on click to button hide parent element  -->
        <!-- <button #closeBtnElm class="btn btn-icon small alert-bar-close-button" [svgIcon]="'close'" iconSize="sm" (click)="close(closeBtnElm);"></button> -->
      </div>
    </ng-container>
  </div>
</ng-container>
