import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Observer, Subject, takeUntil } from 'rxjs';

@Injectable()
export class SubscriptionService implements OnDestroy {
  private destroyed$ = new Subject<void>();

  subscribeUntilDestroyed<T>(observable: Observable<T>, observerOrNext?: Partial<Observer<T>> | ((value: T) => void)): void {
    observable.pipe(takeUntil(this.destroyed$)).subscribe(observerOrNext);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
