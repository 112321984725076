import { Component } from '@angular/core';
import { ModalCloseType } from 'src/app/shared/components/modal/modal-close-type.enum';
import { BaseModalDialogDirective } from 'src/app/shared/directives/base-modal-dialog.directive';
import { ModalSize } from 'src/app/shared/enums/modal-size.enum';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss'],
})
export class ServerErrorComponent extends BaseModalDialogDirective {
  constructor(public navigationService: NavigationService) {
    super();
  }

  get size(): ModalSize {
    return ModalSize.Small;
  }

  get closeType(): ModalCloseType {
    return ModalCloseType.Safe;
  }

  public reloadCurrentPage(): void {
    window.location.reload();
  }
}
