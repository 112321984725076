import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '../enums/generated.enums';
import { ApiConversionHelper } from '../helpers/api-conversion.helper';
import { CurrencyHelper } from '../helpers/currency.helper';
import { DateHelper } from '../helpers/date.helper';
import { TextHelper } from '../helpers/text.helper';
import { Data } from '../types/data.type';
import { OrganizationName } from '../types/organization-name.type';
import { OrganizationConfiguration } from '../types/organization.type';
import { ApiService } from './api/api.service';
import { AuthService } from './auth.service';
import { DataAvailabilityService } from './data-availability.service';
import { LoggingService } from './logging.service';
import { NavigationService } from './navigation.service';
import { RegionCacheService } from './region.service';
import { SchedulerCacheService } from './scheduler-cache.service';
import { SidepanelService } from './sidepanel.service';
import { SignalRService } from './signalr.service';
import { StorageService } from './storage.service';
import { EventBusService, EventType } from './event-bus.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
  // organizations
  private _organizations: OrganizationName[];

  get selectedOrganization(): OrganizationName {
    return this.authService.user && this.organizations ? this.organizations.find(o => o.name === this.authService.user.organization) : null;
  }

  get isDemoOrganization(): boolean {
    return this.selectedOrganization?.name === 'DemoOrganization';
  }

  get isDemoOrchestration(): boolean {
    return this.selectedOrganization?.name === 'DemoOrchestration';
  }

  get isTestOrganization(): boolean {
    return this.selectedOrganization?.name === 'TestOrganization';
  }

  get organizations(): OrganizationName[] {
    return this._organizations;
  }

  organizationConfigurationSubject = new BehaviorSubject(this.organizationConfiguration);
  get organizationConfiguration(): OrganizationConfiguration {
    return this.data.organizationConfiguration;
  }

  private set organizationConfiguration(organizationConfiguration: OrganizationConfiguration) {
    this.data.organizationConfiguration = organizationConfiguration;
  }

  get is24HFormat(): boolean {
    return this.organizationConfiguration && this.organizationConfiguration.is24HFormat;
  }

  get isDemoOrTestOrganization(): boolean {
    const demoOrganizations = ['DemoOrganization', 'TestOrganization'];
    return this.selectedOrganization && demoOrganizations.includes(this.selectedOrganization.name);
  }

  get lastDate(): Date {
    const daysOffset = this.hasPlatform(Platform.UiPath) ? 0 : 1;
    return DateHelper.subtractDays(DateHelper.startOfToday(), daysOffset);
  }

  constructor(
    private data: Data,
    private apiService: ApiService,
    private authService: AuthService,
    private loggingService: LoggingService,
    private signalRService: SignalRService,
    private storageService: StorageService,
    private dataAvailabilityService: DataAvailabilityService,
    private schedulerCacheService: SchedulerCacheService,
    private sidepanelService: SidepanelService,
    private regionService: RegionCacheService,
    private navigationService: NavigationService,
    private eventBusService: EventBusService,
  ) {}

  clear(): void {
    // clear for organizations selection
    this._organizations = null;
    this.organizationConfiguration = null;
    this.data.organizationConfiguration = null;
  }

  getPlatform(): Platform {
    return this.data.platform;
  }

  hasPlatform(platform: Platform): boolean {
    return this.data.hasPlatform(platform);
  }

  async initializeOrganizationsIfNeeded(): Promise<void> {
    if (this.organizations == null) {
      await this.initializeOrganizations();
    }
    // TODO: find a better way to set the currency
    // analyses could have different currencies (uses process.currency) so its need to reset the currency after each analysis
    if (this.organizationConfiguration != null) {
      CurrencyHelper.setCurrency(this.organizationConfiguration.currencyCode);
    }
  }

  private async initializeOrganizations(): Promise<void> {
    let organizations: OrganizationName[] = [];
    try {
      organizations = await this.apiService.getOrganizationNames();
      organizations = organizations.sort((a, b) => a.label.localeCompare(b.label));
    } catch (error: any) {
      this.loggingService.logException(error);
      return;
    }
    organizations.forEach(o => {
      o.urlName = TextHelper.getUrlName(o.name);
      o.lowerCaseName = o.name.toLowerCase();
    });
    this._organizations = organizations;
    await this.initializeOrganizationConfiguration();
  }

  public async initializeOrganizationConfiguration(): Promise<void> {
    try {
      this.organizationConfiguration = await this.apiService.getOrganizationConfiguration();
      this.organizationConfigurationSubject.next(this.organizationConfiguration);
    } catch (error: any) {
      this.loggingService.logException(error);
      this.organizationConfiguration = new OrganizationConfiguration();
    }

    try {
      DateHelper.setTimeZone(this.organizationConfiguration.ianaTimeZoneId);
      DateHelper.setDay24HFormat(this.organizationConfiguration.is24HFormat);
    } catch (error: any) {
      this.loggingService.logException(error);
    }

    CurrencyHelper.setCurrency(this.organizationConfiguration.currencyCode);
    ApiConversionHelper.isBusinessExceptionSuccess = this.organizationConfiguration.isBusinessExceptionSuccess;
  }

  async getOrganizationName(organizationUrlName: string): Promise<{ name: string; label: string; urlName?: string; lowerCaseName?: string }> {
    if (!organizationUrlName) {
      return this.selectedOrganization;
    }
    await this.initializeOrganizationsIfNeeded();
    const organizationLowerCaseName = organizationUrlName.replace(/-/g, '').toLowerCase();
    const organization = this.organizations.find(o => o.urlName === organizationUrlName || o.lowerCaseName === organizationLowerCaseName);
    return organization;
  }

  // organization changed
  async selectOrganization(organizationName: string): Promise<void> {
    if (!organizationName || organizationName === this.selectedOrganization?.name) {
      return;
    }
    this.data.reloading = true;
    this.storageService.clearStorage();
    this.dataAvailabilityService.clear();
    this.schedulerCacheService.clear();
    this.regionService.clear();
    this.navigationService.clear();

    await this.sidepanelService.close();

    await this.initializeOrganizationsIfNeeded();
    try {
      await this.authService.setOrganization(organizationName);
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        this.loggingService.logException(error);
      } else {
        this.data.reloading = false;
        return;
      }
    }

    await this.signalRService.restartConnection();
    await this.initializeOrganizationConfiguration();
    this.data.reloading = false;
    this.eventBusService.emit(EventType.OrganizationChanged);
  }
}
