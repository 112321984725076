import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appFilter' })
export class FilterPipe implements PipeTransform {
  // usually the filter is used on an array of objects, but it can also be used on an array of strings
  // in most cases the filter string is a single string, but it can also be an array of strings (e.g. when using the filter on a multi-select dropdown or with checkboxes)
  transform(items: any[], filterStrings: string[], property?: string): any[] {
    if (!items) {
      return [];
    }
    if (items.length === 0 || !filterStrings || !filterStrings.some(v => v.length > 0)) {
      return items;
    }

    filterStrings = filterStrings.map(v => v.toLocaleLowerCase());

    let filteredItems: any[] = [];
    for (const item of items) {
      if (
        (!property && typeof item === 'string' && filterStrings.some(v => item.includes(v))) ||
        (property && item.hasOwnProperty(property) && typeof item[property] === 'string' && filterStrings.some(v => item[property]?.toLocaleLowerCase().includes(v)))
      ) {
        filteredItems.push(item);
      }
    }
    return filteredItems;
  }
}
