<shared-modal>
  <div class="upload-notification" modalBody>
    <div *ngIf="processingItem.isSuccessful">
      <div class="upload-info success">
        <svg-icon key="done-circle-big"></svg-icon>
        <h2>{{ processingItem.processIds.length === 1 ? 'Your analysis is ready' : 'Your analyses are ready' }}</h2>
      </div>
      <div *ngIf="processingItem.someLogsWereMissing" class="content-panel missing-logs-panel">
        <div>
          <svg-icon key="warning"></svg-icon>
        </div>
        <div>
          <p>The file you uploaded was missing some important information. It might have some influence on the accuracy of information you’ll see for this process.</p>
          <button type="button" class="btn btn-link" (click)="downloadMissingRows()">Download a list of missing rows</button>
        </div>
      </div>
      <div class="button-container">
        <button type="button" class="btn btn-link" (click)="navigateToAddedAnalysis()">
          {{ processingItem.processIds.length === 1 ? 'View the analysis' : 'View the analyses' }}
        </button>
      </div>
    </div>

    <div *ngIf="processingItem.isUploaded">
      <div class="upload-info success">
        <svg-icon key="uploaded-circle"></svg-icon>
        <h2>Uploaded successfully</h2>
      </div>
      <div class="content-panel">
        <p class="text-center">
          The file doesn't seem to be in a format Pointee is able to process automatically. But don't worry, we have received your file, and our suppport team has been notified.
        </p>
        <p class="text-center">Our support team will get in touch with you soon.</p>
      </div>
      <div class="button-container">
        <button type="button" class="btn btn-link" (click)="close()">Understood</button>
      </div>
    </div>

    <div *ngIf="processingItem.isError">
      <ng-container *ngIf="processingItem.error === ProcessingItemError.UploadFailed">
        <div class="upload-info error">
          <svg-icon key="no-connection-circle"></svg-icon>
          <h2>Upload failed</h2>
        </div>
        <div class="content-panel">
          <p class="text-center">Something went wrong while your file was still being uploaded. Please check your internet connection and try again.</p>
        </div>
        <div class="button-container">
          <button type="button" class="btn btn-link" (click)="tryAgain()">Try again</button>
        </div>
      </ng-container>
      <ng-container *ngIf="processingItem.error === ProcessingItemError.SignalRDisconnected">
        <div class="upload-info error">
          <svg-icon key="no-connection-circle"></svg-icon>
          <h2>Connection error</h2>
        </div>
        <div class="content-panel">
          <p class="text-center">The connection to the server has been interrupted while your file was still being processed. Therefore, the result of the operation is unknown.</p>
          <p class="text-center">Please check the list of your analyses whether the processing has been successful.</p>
        </div>
        <div class="button-container">
          <button type="button" class="btn btn-link" (click)="refreshAnalyses()">Understood</button>
        </div>
      </ng-container>
    </div>
  </div>
</shared-modal>
