import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { ResourceGroup } from '../../types/monitoring/resource-group.type';

@Injectable({ providedIn: 'root' })
export class ResourceGroupsApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/resource-groups`;
  }

  async getResourceGroups(): Promise<ResourceGroup[]> {
    return await this.get<ResourceGroup[]>('');
  }

  async createResourceGroup(resourceGroup: ResourceGroup): Promise<void> {
    return await this.post<ResourceGroup>('', resourceGroup);
  }

  async updateResourceGroup(resourceGroup: ResourceGroup): Promise<void> {
    return await this.put('', resourceGroup);
  }

  async deleteResourceGroup(resourceGroup: ResourceGroup): Promise<void> {
    return await this.delete(`${resourceGroup.id}`);
  }
}
