import { Injectable } from '@angular/core';
import { Domain } from '../../types/domain.type';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class DomainsApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/domains`;
  }

  async getDomains(): Promise<Domain[]> {
    return await this.get<Domain[]>('');
  }

  async createDomain(domain: Domain): Promise<void> {
    return await this.post<Domain>('', domain);
  }

  async updateDomain(domain: Domain): Promise<void> {
    return await this.put('', domain);
  }

  async deleteDomain(domain: Domain): Promise<void> {
    return await this.delete(`${domain.id}`);
  }
}
