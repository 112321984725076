import { Injectable } from '@angular/core';
import { MonitoringMetricType, MetricSourceType, MetricAggregationFunc } from '../../enums/generated.enums';
import { ApiConversionHelper } from '../../helpers/api-conversion.helper';
import { DateHelper } from '../../helpers/date.helper';
import { AnomalySources } from '../../types/anomaly-sources.type';
import { MetricBarChartData, MultiMetricBarChartItem } from '../../types/metric-barchart-data.type';
import { SupportedMetrics } from '../../types/metric-description.type';
import { MetricFilters } from '../../types/metric-filters.type';
import { MetricData } from '../../types/metric-timeseries-data.type';
import { TopExceptionsCountTrend } from '../../types/top-exceptions-count-trend.type';
import { PlatformTextsService } from '../platform-texts.service';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class MetricsApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/metrics`;
  }

  async getSupportedMetrics(platformTexts: PlatformTextsService): Promise<SupportedMetrics> {
    const result = await this.get<SupportedMetrics>('supported');
    if (platformTexts != null) {
      result.metrics.forEach(m => (m.label = platformTexts.translate(m.label)));
      result.combinedMetrics.forEach(m => (m.label = platformTexts.translate(m.label)));
    }
    return result;
  }

  async getAvailableMetrics(from: Date, to: Date, filters?: MetricFilters): Promise<MonitoringMetricType[]> {
    return await this.get<MonitoringMetricType[]>(`available/${DateHelper.formatApiDateOnly(from)}/${DateHelper.formatApiDateOnly(to)}`, filters);
  }

  async getMetricData(metricType: MonitoringMetricType, from: Date, to: Date, filters: MetricFilters, bucketSize?: number): Promise<MetricData> {
    const result = await this.get<MetricData>(`${metricType}/${DateHelper.formatApiDateTime(from)}/${DateHelper.formatApiDateTime(to)}`, { ...filters, bucketSize });
    ApiConversionHelper.convertDates(result.samples, ['timeStamp']);
    return result;
  }

  async getMetricPerSelectedOwners(metricType: MonitoringMetricType, date: Date, ownerIds: string[]): Promise<MetricBarChartData> {
    return await this.get<MetricBarChartData>(`${metricType}/per-selected-owners/${DateHelper.formatApiDateOnly(date)}`, { ownerIds });
  }

  async getMetricBreakdownPerOwner(metricType: MonitoringMetricType, from: Date, to: Date, maxCount: number, filters?: MetricFilters): Promise<MetricBarChartData> {
    return await this.get<MetricBarChartData>(`${metricType}/per-owner/${DateHelper.formatApiDateTime(from)}/${DateHelper.formatApiDateTime(to)}`, { ...filters, maxCount });
  }

  async getMetricBreakdownPerHour(
    metricType: MonitoringMetricType,
    from: Date,
    to: Date,
    businessHoursOnly: boolean,
    aggregationFunc: MetricAggregationFunc,
    filters?: MetricFilters,
  ): Promise<MetricBarChartData> {
    return await this.get<MetricBarChartData>(`${metricType}/per-hour/${DateHelper.formatApiDateTime(from)}/${DateHelper.formatApiDateTime(to)}`, {
      ...filters,
      aggregationFunc,
      businessHoursOnly,
    });
  }

  async getMetricBreakdownPerWeekDay(metricType: MonitoringMetricType, from: Date, to: Date, aggregationFunc: MetricAggregationFunc, filters?: MetricFilters): Promise<MetricBarChartData> {
    return await this.get<MetricBarChartData>(`${metricType}/per-weekday/${DateHelper.formatApiDateTime(from)}/${DateHelper.formatApiDateTime(to)}`, { ...filters, aggregationFunc });
  }

  async getMetricBreakdownPerResource(
    metricType: MonitoringMetricType,
    from: Date,
    to: Date,
    maxCount: number,
    aggregationFunc: MetricAggregationFunc,
    filters?: MetricFilters,
  ): Promise<MetricBarChartData> {
    return await this.get<MetricBarChartData>(`${metricType}/per-resource/${DateHelper.formatApiDateTime(from)}/${DateHelper.formatApiDateTime(to)}`, { ...filters, aggregationFunc, maxCount });
  }

  async getTopExceptions(from: Date, to: Date, sourceType: MetricSourceType, exceptionCount: number, filters?: MetricFilters): Promise<MetricBarChartData> {
    return await this.get<MetricBarChartData>(`top-exceptions/${sourceType}/${DateHelper.formatApiDateTime(from)}/${DateHelper.formatApiDateTime(to)}`, { ...filters, exceptionCount });
  }

  async getTopExceptionSources(from: Date, to: Date, sourceType: MetricSourceType, exceptionCount: number, filters?: MetricFilters): Promise<MetricBarChartData> {
    return await this.get<MetricBarChartData>(`top-exception-sources/${sourceType}/${DateHelper.formatApiDateTime(from)}/${DateHelper.formatApiDateTime(to)}`, { ...filters, exceptionCount });
  }

  async getTopExceptionsTrendOverDays(from: Date, to: Date, exceptionCount: number, filters?: MetricFilters): Promise<TopExceptionsCountTrend> {
    return await this.get<TopExceptionsCountTrend>(`top-exceptions-trend/count-over-days/${DateHelper.formatApiDateTime(from)}/${DateHelper.formatApiDateTime(to)}`, {
      ...filters,
      exceptionCount,
    });
  }

  async getTopExceptionsTrendOverHours(from: Date, to: Date, exceptionCount: number, filters?: MetricFilters): Promise<TopExceptionsCountTrend> {
    return await this.get<TopExceptionsCountTrend>(`top-exceptions-trend/rate-over-hours/${DateHelper.formatApiDateTime(from)}/${DateHelper.formatApiDateTime(to)}`, {
      ...filters,
      exceptionCount,
    });
  }

  async getMostIncreasingExceptions(from: Date, to: Date, periodLength: number, exceptionCount: number, filters?: MetricFilters): Promise<MultiMetricBarChartItem[]> {
    return await this.get<MultiMetricBarChartItem[]>(`most-increasing-exceptions/${DateHelper.formatApiDateTime(from)}/${DateHelper.formatApiDateTime(to)}`, {
      ...filters,
      periodLength,
      exceptionCount,
    });
  }

  async getAnomalies(from: Date, to: Date, filters?: MetricFilters): Promise<MetricData> {
    const result = await this.get<MetricData>(`anomalies/${DateHelper.formatApiDateTime(from)}/${DateHelper.formatApiDateTime(to)}`, { ...filters });
    ApiConversionHelper.convertDates(result.samples, ['timeStamp']);
    return result;
  }

  async getAnomalySources(from: Date, to: Date, maxCount: number): Promise<AnomalySources> {
    return await this.get<AnomalySources>(`anomalies-sources/${DateHelper.formatApiDateTime(from)}/${DateHelper.formatApiDateTime(to)}`, { maxCount });
  }
}
