<div class="file-drop-box">
  <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="onFileDrop($event)" [accept]="acceptedFileTypes" [multiple]="allowMultipleFiles" [directory]="false">
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <div class="drag-drop-content">
        <div class="text header">Drag your files here<span *ngIf="acceptedFileTypesText"> ({{acceptedFileTypesText}})</span></div>
        <div class="text header-separator">or</div>
        <button type="button" class="btn btn-primary fit-content" (click)="openFileSelector()">
          <span>Select files from your computer</span>
        </button>
      </div>
    </ng-template>
  </ngx-file-drop>
</div>