import { Component, Input } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { BaseModalDialogDirective } from 'src/app/shared/directives/base-modal-dialog.directive';
import { Platform } from 'src/app/shared/enums/generated.enums';
import { OrganizationService } from 'src/app/shared/services/organization.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { UploadService } from 'src/app/shared/services/upload.service';
import { PlatformSpecifics } from './platform-specific.type';

@Component({
  selector: 'app-upload-files-modal',
  templateUrl: './upload-files-modal.component.html',
  styleUrls: ['./upload-files-modal.component.scss'],
})
export class UploadFilesModalComponent extends BaseModalDialogDirective {
  @Input() monitoringProcessGroupId: string;
  @Input() monitoringProcessGroupLabel: string;
  @Input() monitoringProcessesLabels: string[];

  platforms?: { value: Platform; label: string }[];
  platform: PlatformSpecifics;

  get selectedPlatform(): Platform {
    return this._selectedPlatform;
  }

  set selectedPlatform(selectedPlatform: Platform) {
    this._selectedPlatform = selectedPlatform;
    this.storageService.selectedPlatform = this._selectedPlatform;
    this.platform = this.getPlatformSpecifics(this._selectedPlatform);
  }

  private _selectedPlatform: Platform;

  constructor(private uploadService: UploadService, private storageService: StorageService, private organizationService: OrganizationService) {
    super();

    const platforms = this.organizationService.organizationConfiguration.platforms;
    if (platforms.length > 1) {
      this.selectedPlatform =
        this.storageService.selectedPlatform && platforms.includes(this.storageService.selectedPlatform) ? this.storageService.selectedPlatform : platforms[0];
      this.platforms = platforms.map(p => ({ value: p, label: this.getPlatformSpecifics(p).label }));
    } else {
      this.selectedPlatform = platforms[0];
      this.platforms = null;
    }
  }

  private getPlatformSpecifics(platform: Platform): PlatformSpecifics {
    switch (platform) {
      case Platform.BluePrism:
        return new PlatformSpecifics(platform, 'Blue Prism', ['.csv']);
      case Platform.UiPath:
        return new PlatformSpecifics(platform, 'UiPath', ['.log', '.csv']);
      case Platform.AutomationAnywhere:
        return new PlatformSpecifics(platform, 'Automation Anywhere', ['.log']);
      default:
        return new PlatformSpecifics(platform, 'Other', null);
    }
  }

  onFileDrop(files: NgxFileDropEntry[]): void {
    this.close();
    files.forEach(sf => (<FileSystemFileEntry>sf.fileEntry).file(f => this.uploadService.uploadProcess(f, this.selectedPlatform, this.monitoringProcessGroupId)));
  }
}
