import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { LoadingScreenService } from 'src/app/shared/services/loading-screen.service';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingScreenComponent {
  isVisible$: Observable<boolean>;

  constructor(private loadingScreenService: LoadingScreenService, private cdr: ChangeDetectorRef) {
    this.isVisible$ = this.loadingScreenService.isLoadingScreenVisible$.pipe(tap(() => setTimeout(() => this.cdr.detectChanges(), 0)));
  }
}
