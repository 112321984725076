import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class SchedulerDumpApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/scheduler-dump`;
  }

  public async getSize(): Promise<number> {
    return await this.get<number>('size');
  }

  public downloadDumps(): void {
    this.download('');
  }

  public async deleteDumps(): Promise<void> {
    return await this.delete('');
  }
}
