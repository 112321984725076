import { Injectable } from '@angular/core';
import { MonitoringMetricType } from '../../enums/generated.enums';
import { ApiConversionHelper } from '../../helpers/api-conversion.helper';
import { DateHelper } from '../../helpers/date.helper';
import { MetricFilters } from '../../types/metric-filters.type';
import { MetricPrediction } from '../../types/metric-prediction.type';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class MetricPredictionsApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/metric-predictions`;
  }

  async getPredictions(metricType: MonitoringMetricType, from: Date, to: Date, filters?: MetricFilters): Promise<MetricPrediction[]> {
    const result = await this.get<MetricPrediction[]>(`${metricType}/${DateHelper.formatApiDateOnly(from)}/${DateHelper.formatApiDateOnly(to)}`, filters);
    ApiConversionHelper.convertDates(result, ['timeStamp']);
    return result;
  }

  async getAvailablePredictions(from: Date, to: Date, filters?: MetricFilters): Promise<MonitoringMetricType[]> {
    return await this.get<MonitoringMetricType[]>(`available/${DateHelper.formatApiDateOnly(from)}/${DateHelper.formatApiDateOnly(to)}`, filters);
  }
}
