import { Injectable } from '@angular/core';
import { ApiConversionHelper } from '../../helpers/api-conversion.helper';
import { DateHelper } from '../../helpers/date.helper';
import { BaseEntity } from '../../types/base-entity.type';
import { DateRange } from '../../types/date-range.type';
import { EventLogFilter } from '../../types/event-log-filter.type';
import { ProcessDetail } from '../../types/process-detail.type';
import { ProcessOverview } from '../../types/process-overview.type';
import { SchedulerEvent } from '../../types/scheduler/scheduler-event.type';
import { Session } from '../../types/session.type';
import { Item } from '../../types/item.type';
import { SessionsFilter } from '../../types/sessions-filter.type';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class MonitoringApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/monitoring`;
  }

  async getDatesWithData(processGroupId?: string): Promise<DateRange[]> {
    const datesWithData = await this.get<DateRange[]>(`dates-with-data/${processGroupId ?? ''}`);
    ApiConversionHelper.convertDates(datesWithData, ['start', 'end']);
    return datesWithData.map(x => new DateRange(DateHelper.startOfDay(x.start), DateHelper.endOfDay(x.end)));
  }

  async getProcesses(from: Date, to: Date): Promise<ProcessOverview[]> {
    const result = await this.get<ProcessOverview[]>(`processes/${DateHelper.formatApiDateTime(from)}/${DateHelper.formatApiDateTime(to)}`);

    result.forEach(p => ApiConversionHelper.convertMonitoringProcessOverview(p));
    return result;
  }

  async getProcessesDetail(): Promise<ProcessDetail[]> {
    return await this.get<ProcessDetail[]>('processes/detail');
  }

  async getProcessList(): Promise<BaseEntity[]> {
    return await this.get<BaseEntity[]>('process-list');
  }

  async getResourceList(): Promise<BaseEntity[]> {
    return await this.get<BaseEntity[]>('resource-list');
  }

  async getSessions(from: Date, to: Date, pageSize: number, pageIndex: number, filter: SessionsFilter): Promise<Session[]> {
    const result = await this.get<Session[]>(`sessions/${DateHelper.formatApiDateTime(from)}/${DateHelper.formatApiDateTime(to)}`, { ...filter, pageSize, pageIndex });

    result.forEach(s => ApiConversionHelper.convertMonitoringProcessSession(s));
    return result;
  }

  async getPendingSessions(): Promise<Session[]> {
    const result = await this.get<Session[]>('sessions/pending');

    result.forEach(s => ApiConversionHelper.convertMonitoringProcessSession(s));
    return result;
  }

  async deleteSession(session: Session): Promise<void> {
    return await this.delete(`session/${session.id}`);
  }

  async getPendingItems(): Promise<Item[]> {
    const result = await this.get<Item[]>('items/pending');

    result.forEach(i => ApiConversionHelper.convertMonitoringProcessItem(i));
    return result;
  }

  async deleteItem(item: Item): Promise<void> {
    return await this.delete(`item/${item.id}`);
  }

  async getSchedulerEventLogDatesWitData(): Promise<DateRange[]> {
    const datesWithData = await this.get<DateRange[]>('scheduler-events/dates-with-data');
    ApiConversionHelper.convertDates(datesWithData, ['start', 'end']);
    return datesWithData.map(x => new DateRange(DateHelper.startOfDay(x.start), DateHelper.endOfDay(x.end)));
  }

  async getSchedulerEventLog(from: Date, to: Date, pageSize: number, pageIndex: number, filter: EventLogFilter): Promise<SchedulerEvent[]> {
    const body = {
      filter,
      pagination: {
        pageIndex,
        pageSize,
      },
    };

    // we have to use POST because URL might be too long because of filters
    const result = await this.postWithResult<any, SchedulerEvent[]>(`scheduler-events/${DateHelper.formatApiDateTime(from)}/${DateHelper.formatApiDateTime(to)}`, body);

    ApiConversionHelper.convertDates(result, ['timeStamp', 'firstOccurrence']);
    return result;
  }
}
