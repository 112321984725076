import { Injectable } from '@angular/core';
import { IncidentEventType } from '../../enums/generated.enums';
import { ApiConversionHelper } from '../../helpers/api-conversion.helper';
import { DateHelper } from '../../helpers/date.helper';
import { DateRange } from '../../types/date-range.type';
import { IncidentEvent } from '../../types/incident-event.type';
import { Incident, IncidentUpdate } from '../../types/incident.type';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class IncidentsApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/incidents`;
  }

  async getDatesWithData(): Promise<DateRange[]> {
    const datesWithData = await this.get<DateRange[]>('dates-with-data');
    ApiConversionHelper.convertDates(datesWithData, ['start', 'end']);
    return datesWithData.map(x => new DateRange(DateHelper.startOfDay(x.start), DateHelper.endOfDay(x.end)));
  }

  async getIncidents(from: Date, to: Date): Promise<Incident[]> {
    const incidents = await this.get<Incident[]>(`${DateHelper.formatApiDateOnly(from)}/${DateHelper.formatApiDateOnly(to)}`);
    ApiConversionHelper.convertIncidents(incidents);
    return incidents;
  }

  async updateIncidentFeedback(id: string, incidentUpdate: IncidentUpdate): Promise<void> {
    await this.patch(`${id}`, incidentUpdate);
  }

  async createIncidentEvent(id: string, type: IncidentEventType): Promise<void> {
    await this.post<IncidentEvent>(`${id}/events`, { incidentId: id, type });
  }
}
