import { Injectable } from '@angular/core';
import { UserConfiguration } from '../../types/user-configuration.type';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class UserConfigurationApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/user-configuration`;
  }

  async getConfiguration(): Promise<UserConfiguration> {
    return await this.get<UserConfiguration>('');
  }

  async updateConfiguration(configuration: UserConfiguration): Promise<void> {
    return await this.put<UserConfiguration>('', configuration);
  }
}
