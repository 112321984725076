export enum ChartColor {
  Border = '#CCD4E6',
  Font = '#6B7280',
  FontTitle = '#3C4049',
  Line = '#1058F5',
  LightBlue = '#EDF2FE',
  Blue = '#1058F5',
  Blue08 = '#1058F516',
  Blue16 = '#1058F529',
  Blue40 = '#1058F566',
  Blue80 = '#1058F5CC',
  LightGreen = '#D7F1E0',
  Green = '#37B965',
  Green10 = '#37B9651A',
  Green20 = '#37B96533',
  Green40 = '#37B96566',
  Green80 = '#37B965CC',
  Orange = '#FEAE5A',
  Orange20 = '#FEAE5A33',
  Orange40 = '#FEAE5A66',
  Orange80 = '#FEAE5ACC',
  Expected = '#FFC107',
  DarkRed = '#BF0D0D',
  Red = '#EF1F1F',
  Red80 = '#EF1F1FCC',
  Red40 = '#EF1F1F66',
  Red20 = '#EF1F1F33',
  DarkGray = '#B3B3B3',
  DarkGray80 = '#B3B3B3CC',
  DarkGray40 = '#B3B3B399',
  DarkGray20 = '#B3B3B333',
  Gray = '#E8E8E8',
  Gray16 = '#E8E8E829',
  Gray80 = '#E8E8E8CC',
  Gray40 = '#E8E8E899',
  BoxAreaRed = '#EF1F1F09',
  BoxAreaRedDarker = '#EF1F1F33',
  Transparent = '#00000000',
  Disabled = '#CCCCCC',
  TooltipBackground = '#000000CC',
}
