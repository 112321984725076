<aside id="side-menu" class="menu-content" [ngClass]="{'expanded': isMenuExpanded}">
  <div class="floating-container" [ngClass]="{'expanded': isMenuExpanded}">
    <!-- menu header component -->
    <div id="menu-header" [ngClass]="{scrolled: isScrolled, expanded: isMenuExpanded}">
      <ng-container *ngIf="!(parentRoute$ | async)">
        <!-- mobile or collapsed -->
        <div class="menu-header-logo-mobile" *ngIf="isMobile || !isMenuExpanded">
          <a [routerLink]="rootRoute" class="mobile-logo">
            <img src="assets/svg/pointee_mobile.svg" alt="Pointee mobile logo" />
          </a>
        </div>
        <!-- expanded -->
        <ng-container *ngIf="isMenuExpanded">
          <!-- desktop logo -->
          <div class="menu-header-logo">
            <a [routerLink]="rootRoute" class="brand-logo">
              <img src="assets/svg/pointee_primary.svg" alt="Pointee brand logo" />
            </a>
          </div>

          <!-- action button -->
          <button (click)="displayOrganizationSelect()" class="icon-btn" [preventFocus]="'OnceAfterClick'" [tooltip]="'Switch to another tenant'" *ngIf="allowOrganizationSelect">
            <svg-icon key="tenant" size="md"></svg-icon>
          </button>
        </ng-container>
      </ng-container>

      <div class="menu-subheader-container" *ngIf="parentRoute$ | async as parentRoute">
        <a [routerLink]="parentRoute" class="icon-btn link" (click)="reloadPage()">
          <ng-container *ngIf="isMenuExpanded"><svg-icon key="exit" fontSize="16px"></svg-icon><span>&nbsp;Exit&nbsp;{{ firstLabel | async}}</span> </ng-container>
          <ng-container *ngIf="!isMenuExpanded"><svg-icon key="exit" fontSize="24px"></svg-icon></ng-container>
        </a>
      </div>
    </div>

    <!-- menu body content -->
    <div #menuBodyContainer id="menu-body" [ngClass]="{expanded: isMenuExpanded}" (scroll)="onBodyContainerScroll($event)">
      <div class="menu-body-container">
        <div *ngFor="let section of menuSections$ | async; let isFirst=first" [ngClass]="{'action-wrapper': section.type === 'actions' }">
          <div class="menu-section">
            <span class="menu-section-title" *ngIf="section.type==='items'">{{ section.label }}</span>
            <span class="separator" *ngIf="!isFirst && (!isMenuExpanded || section.type === 'actions')"></span>

            <div
              *ngFor="let menuItem of section.items"
              [routerLinkActive]="['active']"
              (isActiveChange)="menuItem.active = $event"
              exact="true"
              #rla="routerLinkActive"
              class="menu-item"
              [ngClass]="{active: menuItem.active, disabled: menuItem.disabled}"
              [style.pointer-events]="menuItem.disabled ? 'none' : 'auto'"
              [style.display]="menuItem.hide === true ? 'none' : 'flex'"
              [tooltip]="menuItem.label"
              [display]="!isMenuExpanded"
              (click)="menuItem.onClick && menuItem.onClick($event)"
            >
              <a [routerLink]="menuItem.route || undefined">
                <svg-icon key="{{isMenuExpanded ? menuItem.icon + '-solid' : menuItem.icon}}" [ngClass]="{large: !isMenuExpanded}"></svg-icon>
                <span class="item-label menu-item-label prevent-select" *ngIf="isMenuExpanded">{{ menuItem.label }}</span>

                <ng-container *ngIf="menuItem.badge$ | async as badge;">
                  <ng-container *ngIf="badge > 0">
                    <div *ngIf="menuItem.badgeType === 'warning'" class="badge warning" [class.large]="isMenuExpanded">{{badge}}</div>
                    <span *ngIf="menuItem.badgeType === 'count' && isMenuExpanded" class="menu-item-count">{{badge}}</span>
                  </ng-container>
                </ng-container>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end of menu body content -->

    <!-- menu footer -->
    <div id="menu-footer" [ngClass]="{scrolled: isNextScrolled, expanded: isMenuExpanded}">
      <!-- user menu -->
      <div class="user-menu-button" *ngIf="isMenuExpanded" [matMenuTriggerFor]="profileMenu">
        <svg-icon key="account-24" size="lg"></svg-icon>
        <span class="account-name">{{ user?.userName }}</span>
        <svg-icon key="chevron-down"></svg-icon>
      </div>

      <div class="footer-action-buttons" *ngIf="!isMenuExpanded">
        <button class="icon-btn" (click)="displayOrganizationSelect()" [tooltip]="'Switch to another tenant'">
          <svg-icon key="tenant-24" size="lg"></svg-icon>
        </button>
        <button class="icon-btn" [matMenuTriggerFor]="profileMenu" [tooltip]="'Account'">
          <svg-icon key="account-24" size="lg"></svg-icon>
        </button>
      </div>

      <!-- action button -->
      <div class="footer-collapse-button-container" *ngIf="shouldExpandMenu">
        <button class="icon-btn" (click)="toggleMenuExpanded()" [tooltip]="!isMenuExpanded ? 'Expand menu' : 'Collapse menu'" tooltipHideOnClick>
          <svg-icon *ngIf="isMenuExpanded" key="expand-24-solid"></svg-icon>
          <svg-icon *ngIf="!isMenuExpanded" key="expand-24" size="lg"></svg-icon>
        </button>
      </div>
    </div>
  </div>
</aside>

<mat-menu #profileMenu="matMenu" class="pointee-mat-menu">
  <ng-template matMenuContent>
    <div (click)="$event.stopPropagation(); $event.preventDefault()" class="flex-col p-l-16 p-r-16">
      <h4>{{ user.userName }}</h4>
      <p>{{ user.email}}</p>
    </div>
    <hr class="no-margin-padding" />
    <ng-container *ngIf="isSystemAdmin">
      <button mat-menu-item (click)="gotoAdministrationPage()"><svg-icon key="admin"></svg-icon>Pointee administration</button>
      <button mat-menu-item (click)="gotoDesignSystemOverview()"><svg-icon key="design-system"></svg-icon>Design system</button>
      <hr class="no-margin-padding" />
    </ng-container>
    <!-- <button mat-menu-item *ngIf="areNotificationSettingsEnabled" (click)="openNotificationSettings()"><svg-icon key="alarm-bell"></svg-icon>Notifications settings</button> -->
    <button mat-menu-item (click)="changePassword()" *ngIf="isUserPasswordLoginEnabled"><svg-icon key="password"></svg-icon>Change password</button>
    <button mat-menu-item (click)="signOut()"><svg-icon key="logout-16"></svg-icon>Sign out</button>
  </ng-template>
</mat-menu>
