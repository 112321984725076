import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ProcessBasicInfo } from '../types/process-basic-Info.type';
import { NavigationService } from './navigation.service';
import { SelectionService } from './selection.service';
import { SignalRService } from './signalr.service';

@Injectable({ providedIn: 'root' })
export class SelectionFacadeService {
  oldMasterProcessIds: Set<number>;

  get masterProcesses(): ProcessBasicInfo[] {
    return this.selectionService.masterProcesses;
  }

  get selectedMasterProcess(): ProcessBasicInfo {
    return this.selectionService.selectedMasterProcess;
  }

  get selectedProcess(): ProcessBasicInfo {
    return this.selectionService.selectedProcess;
  }

  masterProcessesChanged: Subject<ProcessBasicInfo[]> = new Subject();

  constructor(private selectionService: SelectionService, private signalRService: SignalRService, private navigationService: NavigationService) {
    this.signalRService.masterProcessDeleted$.subscribe(async () => {
      await this.initializeProcesses();
    });

    this.signalRService.masterProcessUserDataUpdated$.subscribe(async masterProcessId => {
      if (masterProcessId === this.selectedMasterProcess.masterProcessId) {
        await this.selectionService.initializeMasterProcessUserData();
      }
    });

    this.signalRService.masterProcessReportUpdated$.subscribe(async masterProcessId => {
      if (masterProcessId === this.selectedMasterProcess.masterProcessId) {
        await this.selectionService.initializeMasterProcess();
      }
    });

    this.selectionService.masterProcesses$.subscribe(e => {
      this.masterProcessesChanged.next(e);
    });
  }

  async initializeProcesses(): Promise<void> {
    const selectedProcessId = this.selectionService.selectedProcess?.id;
    await this.selectionService.initializeMasterProcesses();
    if (!selectedProcessId || !this.selectionService.selectedProcess) {
      await this.navigationService.navigateToAnalyses();
    } else if (this.selectionService.selectedProcess?.id !== selectedProcessId) {
      await this.navigationService.navigateToNotFound(false);
    }
  }

  public getChildUniqueProcessId(parentUniqueProcessId: string, childProcessId: string): string {
    return this.selectionService.getChildUniqueProcessId(parentUniqueProcessId, childProcessId);
  }
}
