import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { ModuleType, PageType } from '../enums/page-type.enum';
import { BreadcrumbService } from '../services/breadcrumb.service';
import { MenuService } from '../services/menu.service';
import { NavigationService } from '../services/navigation.service';
import { SelectionService } from '../services/selection.service';

@Injectable({ providedIn: 'root' })
export class MenuAuthGuard implements CanActivate, CanDeactivate<void> {
  constructor(private menuService: MenuService, private navigationService: NavigationService, private breadcrumbService: BreadcrumbService, private selectionService: SelectionService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.navigationService.setCurrentRoute(route);

    const module = this.navigationService.currentModule;
    const page = this.navigationService.currentPage;
    const subPage = this.navigationService.currentSubPage;

    this.menuService.clearMenuItems();
    this.breadcrumbService.clear();
    if (module === ModuleType.Admin) {
      this.menuService.initializeAdminMenuItems();
    } else if (page === PageType.RuntimeAnalysis && subPage != null) {
      this.selectionService.clear();
    } else {
      this.menuService.initializeMainMenuItems();
    }
    return true;
  }

  canDeactivate(component: void, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): boolean {
    const page = this.navigationService.currentPage;
    const subPage = this.navigationService.currentSubPage;

    this.menuService.clearMenuItems();
    this.breadcrumbService.clear();
    if (page == PageType.RuntimeAnalysis && subPage != null) {
      this.menuService.initializeMainMenuItems();
    }

    return true;
  }
}
