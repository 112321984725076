import { Directive, HostListener, Input } from '@angular/core';
import { TooltipDirective } from '@looorent/ng2-tooltip-directive';

@Directive({ selector: '[tooltipIfEllipsis]' })
export class TooltipIfEllipsisDirective {
  @Input() display: boolean;

  constructor(private tooltip: TooltipDirective) {}

  @HostListener('mouseenter', ['$event.target'])
  onMouseEnter(element: HTMLElement): void {
    const shouldDisplay =
      this.display != null
        ? this.display
        : element != null && element.scrollWidth > 0
        ? element.scrollWidth > element.offsetWidth
        : element.parentElement.offsetWidth > 0
        ? element.parentElement.offsetWidth < element.offsetWidth
        : false;

    if (!shouldDisplay) {
      this.tooltip.destroyTooltip();
    }
  }
}
