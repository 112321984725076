import { LuxonDateAdapter } from '@angular/material-luxon-adapter';

export class CustomDateAdapter extends LuxonDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return super.getDayOfWeekNames(style == 'narrow' ? 'short' : style);
  }
}
