import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from '../services/navigation.service';

@Injectable({ providedIn: 'root' })
export class RedirectToDefaultAuthGuard implements CanActivate {
  constructor(private navigationService: NavigationService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    await this.navigationService.navigateToDefaultPage(true);
    return false;
  }
}
