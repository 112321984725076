import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { DropdownPanel } from '../../interfaces/dropdown-panel';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements DropdownPanel {
  @Input() closeOnClick = true;
  @Output() closed = new EventEmitter<void>();
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;

  onCloseClick() {
    if (!this.closeOnClick) return;
    this.closed.emit();
  }
}
