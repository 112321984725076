import { Injectable } from '@angular/core';
import { ProcessingItem } from 'src/app/layout/upload-processing/processing-item.type';
import { SignalRService } from './signalr.service';
import { UploadService } from './upload.service';
import { ApiService } from './api/api.service';
import { LoggingService } from './logging.service';
import { UploadItemType } from '../enums/upload-item-type.enum';
import { MasterProcessStatus } from '../enums/generated.enums';
import { ProcessingItemStatus } from 'src/app/layout/upload-processing/processing-item-state.enum';
import { Observable, Subject } from 'rxjs';
import { ProcessingItemError } from 'src/app/layout/upload-processing/processing-item-error.enum';

@Injectable({ providedIn: 'root' })
export class UploadProcessingService {
  private itemsSubject = new Subject<ProcessingItem[]>();
  private allItems: ProcessingItem[] = [];
  private uploadingItems = new Map<string, ProcessingItem>();
  private processingItems = new Map<string, ProcessingItem>();

  get items$(): Observable<ProcessingItem[]> {
    return this.itemsSubject.asObservable();
  }

  constructor(private signalRService: SignalRService, private uploadService: UploadService, private apiService: ApiService, private loggingService: LoggingService) {
    this.uploadService.onUploadStarted(UploadItemType.Process).subscribe(item => {
      const processingItem = new ProcessingItem(item.id, item.fileName, item.data.platform);
      this.uploadingItems.set(processingItem.id, processingItem);
      this.allItems.push(processingItem);
      this.itemsSubject.next(this.allItems);
    });

    this.uploadService.onUploadProgress().subscribe(e => {
      const processingItem = this.uploadingItems.get(e.item.id);
      if (processingItem) {
        processingItem.onProgress(e.progress);
        this.itemsSubject.next(this.allItems);
      }
    });

    this.uploadService.onUploadFinished().subscribe(item => {
      const processingItem = this.uploadingItems.get(item.id);
      if (processingItem) {
        processingItem.onUploadProcessFinished(item.data.masterProcessId);
        this.uploadingItems.delete(processingItem.id);
        this.processingItems.set(processingItem.mainMasterProcessId, processingItem);
        this.itemsSubject.next(this.allItems);
      }
    });

    this.uploadService.onUploadError().subscribe(e => {
      const processingItem = this.uploadingItems.get(e.item.id);
      if (processingItem) {
        processingItem.onError(ProcessingItemError.UploadFailed);
        this.uploadingItems.delete(processingItem.id);
        this.itemsSubject.next(this.allItems);
      }
    });

    this.signalRService.masterProcessAddingProgress$.subscribe(async e => {
      const processingItem = this.processingItems.get(e.masterProcessId);
      if (!processingItem) {
        await this.checkCancelledItems(e.masterProcessId);
        return;
      }

      switch (e.status) {
        case MasterProcessStatus.Uploaded:
          processingItem.status = ProcessingItemStatus.Processing;
          processingItem.onProgress(e.nextOperationProgress);
          break;
        case MasterProcessStatus.Failed:
          processingItem.status = ProcessingItemStatus.Uploaded;
          this.processingItems.delete(processingItem.mainMasterProcessId);
          break;
        default:
          processingItem.status = ProcessingItemStatus.Processing;
          break;
      }

      this.itemsSubject.next(this.allItems);
    });

    this.signalRService.masterProcessAdded$.subscribe(e => {
      const mainMasterProcessId = e.masterProcessIds[0];
      const processingItem = this.processingItems.get(mainMasterProcessId);
      if (!processingItem) {
        return;
      }
      processingItem.onProcessAdded(e.masterProcessIds, e.processIds, e.someLogsWereMissing);
      this.processingItems.delete(processingItem.mainMasterProcessId);
      this.itemsSubject.next(this.allItems);
    });

    this.signalRService.masterProcessDeleted$.subscribe(masterProcessId => {
      const deletedProcess = this.allItems.find(p => p.mainMasterProcessId === masterProcessId);
      if (deletedProcess && !deletedProcess.isCancelled) {
        deletedProcess.status = ProcessingItemStatus.Deleted;
      }
      this.itemsSubject.next(this.allItems);
    });

    this.signalRService.connectionError$.subscribe(() => {
      this.processingItems.forEach(i => i.onError(ProcessingItemError.SignalRDisconnected));
      this.itemsSubject.next(this.allItems);
    });
  }

  async cancelItem(processingItem: ProcessingItem, forceDelete: boolean): Promise<void> {
    if (processingItem.isUploading) {
      this.uploadingItems.delete(processingItem.id);
      this.uploadService.cancelUpload(processingItem.id);
      processingItem.status = ProcessingItemStatus.Cancelled;
    }

    if (processingItem.isPending || processingItem.isProcessing) {
      this.processingItems.delete(processingItem.mainMasterProcessId);
      try {
        await this.apiService.cancelMasterProcess(processingItem.mainMasterProcessId);
        processingItem.status = ProcessingItemStatus.Cancelled;
      } catch (error: any) {
        this.loggingService.logException(error);
        if (forceDelete) {
          await this.apiService.deleteMasterProcess(processingItem.mainMasterProcessId);
          processingItem.status = ProcessingItemStatus.Cancelled;
        }
      }
    }
    if (forceDelete && processingItem.isSuccessful) {
      await this.apiService.deleteMasterProcess(processingItem.mainMasterProcessId);
      processingItem.status = ProcessingItemStatus.Cancelled;
    }

    this.itemsSubject.next(this.allItems);
  }

  async clear(): Promise<void> {
    this.allItems.forEach(async i => await this.cancelItem(i, false));
    this.allItems = [];
    this.uploadingItems.clear();
    this.processingItems.clear();
    this.itemsSubject.next(this.allItems);
  }

  private async checkCancelledItems(masterProcessId: string) {
    if (this.allItems.some(i => i.masterProcessIds.includes(masterProcessId) && i.isCancelled)) {
      try {
        await this.apiService.cancelMasterProcess(masterProcessId);
      } catch (error: any) {
        this.loggingService.logException(error);
      }
    }
  }
}
