import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { BaseEntity } from '../../types/base-entity.type';

@Injectable({ providedIn: 'root' })
export class BillingGroupsApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/billing-groups`;
  }

  async getBillingGroups(): Promise<BaseEntity[]> {
    return await this.get<BaseEntity[]>('');
  }

  async createBillingGroup(billingGroup: BaseEntity): Promise<void> {
    return await this.post<BaseEntity>('', billingGroup);
  }

  async updateBillingGroup(billingGroup: BaseEntity): Promise<void> {
    return await this.put('', billingGroup);
  }

  async deleteBillingGroup(billingGroup: BaseEntity): Promise<void> {
    return await this.delete(`${billingGroup.id}`);
  }
}
