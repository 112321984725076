import { ParamMap, Params } from '@angular/router';
import { DateRange } from '../types/date-range.type';
import { DateHelper } from './date.helper';

export class ParamMapHelper {
  static parseInterval(paramMap: ParamMap): DateRange {
    const from = paramMap.get('from');
    const to = paramMap.get('to');
    return from && to ? new DateRange(DateHelper.parseApiDate(from), DateHelper.parseApiDate(to)) : null;
  }

  static parseDate(paramMap: ParamMap): Date {
    const date = paramMap.get('for');
    return date ? DateHelper.parseApiDate(date) : null;
  }

  static containsIntervalParams(paramMap: ParamMap): boolean {
    return paramMap.has('from') && paramMap.has('to');
  }

  static convertToParams(paramMap: ParamMap): Params {
    const params: Params = {};
    paramMap.keys.forEach(key => (params[key] = paramMap.get(key)));
    return params;
  }

  static createIntervalParams(range: DateRange): Params {
    if (range == null) {
      return null;
    }

    return {
      from: DateHelper.formatApiDateOnly(range.start),
      to: DateHelper.formatApiDateOnly(range.end),
    };
  }

  static createDateParams(date: Date): Params {
    if (date == null) {
      return null;
    }

    return {
      for: DateHelper.formatApiDateOnly(date),
    };
  }
}
