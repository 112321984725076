import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from '../services/navigation.service';
import { OrganizationService } from '../services/organization.service';

@Injectable({ providedIn: 'root' })
export class OrganizationAuthGuard implements CanActivate {
  constructor(private navigationService: NavigationService, private organizationService: OrganizationService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // check organization name
    const organizationUrlName = <string>route.params.organizationName;
    const organizationName = await this.organizationService.getOrganizationName(organizationUrlName);
    if (organizationName == null) {
      await this.navigationService.navigateToNotFound(true);
      return false;
    }

    if (organizationUrlName !== organizationName.urlName) {
      const url = decodeURI(state.url).replace(organizationUrlName, organizationName.urlName);
      await this.navigationService.replaceUrl(url);
      return false;
    }

    await this.organizationService.selectOrganization(organizationName.name);
    if (organizationName.name !== this.organizationService.selectedOrganization.name) {
      await this.navigationService.navigateToNotFound(true);
      return false;
    }

    return true;
  }
}
