import { ChangeDetectionStrategy, Component, EventEmitter, Inject, InjectionToken, Input, Output, ViewChild } from '@angular/core';
import { DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel } from '@angular/material/datepicker';
import { DateTime } from 'luxon';
import { DateHelper, DateRangeFormat } from '../../../helpers/date.helper';
import { DataAvailabilityInfo } from '../../../types/data-availability-info.type';
import { DateRange } from '../../../types/date-range.type';
import { DropdownComponent } from '../../dropdown/dropdown.component';

export const CALENDAR_CONFIG = new InjectionToken<CalendarConfig>('CALENDAR_CONFIG');

export interface CalendarConfig {
  showTodayButton?: boolean;
  showYesterdayButton?: boolean;
  show7DaysButton?: boolean;
  show30DaysButton?: boolean;
}

@Component({
  selector: 'shared-calendar-interval-dropdown',
  templateUrl: './calendar-interval-dropdown.component.html',
  styleUrls: ['./calendar-interval-dropdown.component.scss'],
  providers: [DefaultMatCalendarRangeStrategy, MatRangeDateSelectionModel],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarIntervalDropdownComponent {
  @Input() selectedRange: DateRange;
  @Input() dataAvailability: DataAvailabilityInfo;
  @Input() disabled: boolean;
  @Output() selectedRangeChange = new EventEmitter<DateRange>();
  @ViewChild(DropdownComponent, { static: false }) childRef: DropdownComponent;

  activeDate: Date;

  get selectedRangeLabel(): string {
    return this.selectedRange.format(DateRangeFormat.DateOnly);
  }

  get minDateTime(): DateTime {
    return this.dataAvailability?.firstDate != null ? DateTime.fromJSDate(this.dataAvailability.firstDate) : null;
  }

  constructor(@Inject(CALENDAR_CONFIG) public config: CalendarConfig) {
    this.selectedRange = new DateRange(DateHelper.startOfToday());
  }

  onClose() {
    this.activeDate = this.selectedRange.start;
  }

  onSelectedRangeChanged(value: DateRange): void {
    this.selectedRange = value;
    this.selectedRangeChange.emit(value);
    this.childRef?.closed.emit();
  }
}
