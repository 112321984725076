import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-file-drop-box',
  templateUrl: './file-drop-box.component.html',
  styleUrls: ['./file-drop-box.component.scss']
})
export class FileDropBoxComponent {
  @Input() acceptedFileTypes: string;
  @Input() acceptedFileTypesText: string;
  @Input() allowMultipleFiles = false;
  @Output() fileDrop = new EventEmitter<NgxFileDropEntry[]>();

  onFileDrop(files: NgxFileDropEntry[]): void {
    const supportedFiles = files.filter(f => f.fileEntry.isFile);
    if (supportedFiles.length === 0) {
      return;
    }

    this.fileDrop.emit(supportedFiles)
  }
}
