<div class="breadcrumb-container" *ngIf="breadcrumbItems?.length > 0">
  <ng-container *ngFor="let breadcrumb of breadcrumbItems, let i = index">
    <ng-container *ngIf="shouldDisplayItem(i)">
      <a *ngIf="breadcrumb.route" [routerLink]="breadcrumb.route" id="breadcrumbLevel{{i}}" class="breadcrumb-item">
        <span [tooltip]="breadcrumb.tooltip" [options]="ellipsisTooltipOptions" (mouseover)="onMouseOver($event)" class="item-text">{{breadcrumb.label}}</span>
      </a>
      <div *ngIf="!breadcrumb.route" class="breadcrumb-item">
        <span [tooltip]="breadcrumb.tooltip" [options]="ellipsisTooltipOptions" (mouseover)="onMouseOver($event)" class="item-text">{{breadcrumb.label}}</span>
      </div>

      <svg-icon key="arrow-button-right" size="xs" *ngIf="shouldDisplayArrow(i)" class="arrow-icon"></svg-icon>
    </ng-container>
  </ng-container>
</div>

<button *ngIf="displaySubprocessPath" id="jumpToButton" class="btn-ghost-primitive jump-to-button" [tooltip]="'Jump to ...'" (click)="openJumpToPanel()" aria-label="jump to button">
  <svg-icon key="jump-to" size="lg"></svg-icon>
</button>
