/* eslint-disable no-param-reassign */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { BaseModalDialogDirective } from '../../../directives/base-modal-dialog.directive';
import { ModalSize } from '../../../enums/modal-size.enum';
import { NavigationService } from '../../../services/navigation.service';
import { SelectionFacadeService } from '../../../services/selection-facade.service';
import { CustomTreeControl } from '../../../types/custom-tree-control';
import { ProcessBasicInfo } from '../../../types/process-basic-Info.type';
import { ProcessTreeNode } from './process-tree-node.type';

export class ProcessFlatNode {
  constructor(public expandable: boolean, public level: number, public visible: boolean, public found: boolean, public process: ProcessBasicInfo) {}
}

@Component({
  selector: 'app-jump-to-modal',
  templateUrl: './jump-to-modal.component.html',
  styleUrls: ['./jump-to-modal.component.scss'],
})
export class JumpToModalComponent extends BaseModalDialogDirective implements OnInit {
  treeControl: CustomTreeControl<ProcessTreeNode>;
  dataSource: MatTreeFlatDataSource<ProcessBasicInfo, ProcessTreeNode>;
  searchValue = '';

  expandable = (index: number, node: ProcessTreeNode): boolean => node.expandable;
  isSelected = (node: ProcessTreeNode): boolean => Boolean(node && this.selectionFacadeService.selectedProcess && node.process.id === this.selectionFacadeService.selectedProcess.id);

  get size(): ModalSize {
    return ModalSize.Medium;
  }

  private _treeFlattener: MatTreeFlattener<ProcessBasicInfo, ProcessTreeNode>;

  constructor(private selectionFacadeService: SelectionFacadeService, private navigationService: NavigationService) {
    super();

    this.selectionFacadeService.masterProcessesChanged.subscribe(() => this.initialize());

    this.treeControl = new CustomTreeControl<ProcessTreeNode>(
      n => n.level,
      n => n.expandable,
    );
    this._treeFlattener = new MatTreeFlattener(
      this.transformer,
      n => n.level,
      n => n.expandable,
      p => p.children,
    );
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this._treeFlattener);
  }

  ngOnInit(): void {
    this.initialize();
  }

  transformer(process: ProcessBasicInfo, level: number): ProcessTreeNode {
    return new ProcessFlatNode(Boolean(process.children && process.children.length > 0), level, true, true, process);
  }

  initialize(): void {
    this.searchValue = '';
    this.dataSource.data = this.selectionFacadeService.selectedMasterProcess != null ? [this.selectionFacadeService.selectedMasterProcess] : [];
    this.expandNode(this.selectionFacadeService.selectedProcess);
  }

  onSearchInputClear(event: any): void {
    this.initialize();
  }

  onSearchChanged(filterText: string): void {
    filterText = filterText.trim().toLowerCase();
    if (this.searchValue === filterText) {
      return;
    }
    this.searchValue = filterText;

    if (filterText?.length === 0) {
      this.treeControl.dataNodes?.forEach(n => {
        n.visible = true;
        n.found = true;
      });
      this.treeControl.collapseAll();
      return;
    }

    this.treeControl.dataNodes.forEach(n => {
      n.visible = false;
      n.found = false;
      this.treeControl.collapse(n);
    });
    if ('main page'.includes(filterText)) {
      this.treeControl.dataNodes[0].visible = true;
      this.treeControl.dataNodes[0].found = true;
    }
    this.treeControl.dataNodes
      .filter(n => n.process.label.toLowerCase().includes(filterText))
      .forEach(n => {
        n.visible = true;
        n.found = true;
        this.showAllAncestors(n);
      });
  }

  async setProcess(node: ProcessTreeNode): Promise<void> {
    this.close();
  }

  getUrl(processId: string): string[] {
    return this.navigationService.getCurrentPageRoute(processId);
  }

  private expandNode(process: ProcessBasicInfo) {
    if (!process) {
      return;
    }
    this.treeControl?.dataNodes
      .filter(n => n.process.id === process.id)
      .forEach(n => {
        this.treeControl.expandParents(n);
        this.treeControl.expand(n);
      });
  }

  private showAllAncestors(node: ProcessTreeNode) {
    const parent = this.treeControl.getParent(node);
    if (parent) {
      parent.visible = true;
      this.treeControl.expand(parent);
      this.showAllAncestors(parent);
    }
  }
}
