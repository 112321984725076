import { FormatType } from '../enums/format-type.enum';
import { ActionableInsightType } from '../enums/generated.enums';
import { ActionableInsight, ItemDurationTrendActionableInsight, OutliersActionableInsight, WaitOptimizationActionableInsight } from '../types/actionable-insight.type';
import { BucketedUtilization } from '../types/bucketed-utilization.type';
import { Incident } from '../types/incident.type';
import { MonitoringMetrics, PendingsMetrics } from '../types/items-metrics.type';
import { LicensesUtilization } from '../types/licenses-utilization.type';
import { ManagementMetricsEntry, ManagementMetricsHistory } from '../types/management-metrics-result.type';
import { MasterProcessUserData } from '../types/master-process-user-data.type';
import { MasterProcess } from '../types/master-process.type';
import { MonitoringResource } from '../types/monitoring/monitoring-resource.type';
import { ProcessBasicInfo } from '../types/process-basic-Info.type';
import { ProcessGroupDetail } from '../types/process-group-detail.type';
import { ProcessGroupOverview } from '../types/process-group-overview.type';
import { ProcessOverview } from '../types/process-overview.type';
import { Process } from '../types/process.type';
import { ResourcesUtilization } from '../types/resources-utilization.type';
import { Schedule } from '../types/schedule.type';
import { SchedulerTaskConfiguration } from '../types/scheduler/scheduler-task.type';
import { Session } from '../types/session.type';
import { Item } from '../types/item.type';
import { EdgeStatistics } from '../types/statistics/edge-statistics.type';
import { ItemsStatistics } from '../types/statistics/items-statistics.type';
import { NodeStatistics } from '../types/statistics/node-statistics.type';
import { ProcessStatistics } from '../types/statistics/process-statistics.type';
import { SuccessRate } from '../types/success-rate.type';
import { Variant } from '../types/variant.type';
import { DateHelper } from './date.helper';
import { DateRangeFormat } from './date.helper';
import { FormatHelper } from './format.helper';
import { MathHelper } from './math.helper';

export class ApiConversionHelper {
  static isBusinessExceptionSuccess = false;

  static convertMasterProcess(data: MasterProcess): void {
    this.convertDates(data, ['processingDateTime', 'itemEnds']);
    this.convertDates(data.sessionsAttributesValues, ['dates']);
    this.convertDates(data.itemsAttributesValues, ['dates']);
    this.convertTimeSpans(data, ['itemDurations']);
    this.convertTimeSpansRecursive(data.flameGraphRoot, 'children', ['meanDuration']);
    this.convertTimeSpans(data.activityTypes, ['meanDuration']);
    this.convertStatistics([data.itemsProcessStatistics, data.sessionsProcessStatistics]);
    this.convertActionableInsights(data.actionableInsights);
  }

  static convertProcess(data: Process): void {
    this.convertDates(data, ['from', 'to', 'historyDays']);
    this.convertTimeSpans(data.nodes, ['normDuration']);
    this.convertVariants(this.objectValues(data.variants));
    this.convertStatistics(data.nodes.map(n => n.statistics));
    this.convertStatistics(data.edges.map(n => n.statistics));
    this.convertStatistics([data.statistics]);
  }

  static convertMasterProcessUserData(data: MasterProcessUserData): void {
    this.convertTimeSpans(data.userActionableInsights, ['savedItemMeanDuration']);
  }

  static convertMonitoringProcessGroupOverview(data: ProcessGroupOverview): void {
    this.convertManagementMetricsEntry(data.metrics);
    this.convertManagementMetricsEntry(data.previousMetrics);
    this.convertPendingMetrics(data.pending);
  }

  static convertManagementMetricsEntry(data: ManagementMetricsEntry): void {
    this.convertDates(data, ['fromDate', 'toDate']);
    this.convertTimeSpans(data, ['tasksAverageDuration']);
  }

  static convertManagementMetricsHistory(data: ManagementMetricsHistory): void {
    this.convertDates(data, ['fromDates', 'toDates']);
    this.convertTimeSpans(data, ['tasksAverageDuration']);
  }

  static convertMonitoringProcessGroupDetail(data: ProcessGroupDetail): void {
    this.convertManagementMetricsEntry(data.metrics.aggregated);
    this.convertManagementMetricsEntry(data.previousMetrics);
    this.convertManagementMetricsEntry(data.overallMetrics);
    this.convertManagementMetricsHistory(data.metrics.history);
    this.convertPendingMetrics(data.pending);
  }

  static convertMonitoringProcessOverview(data: ProcessOverview): void {
    this.convertDates(data, ['lastRun']);
    this.convertTimeSpans(data, ['lastRunTime']);
    ApiConversionHelper.convertMonitoringMetrics(data.itemsMetrics);
    ApiConversionHelper.convertMonitoringMetrics(data.sessionsMetrics);
  }

  static convertMonitoringProcessSession(data: Session): void {
    this.convertDates(data, ['added', 'started', 'ended']);
  }

  static convertMonitoringProcessItem(data: Item): void {
    this.convertDates(data, ['added', 'started', 'ended', 'deferred']);
  }

  static convertMonitoringMetrics(data: MonitoringMetrics): void {
    if (data == null) {
      return;
    }
    this.convertTimeSpans(data, ['averageDuration', 'totalDuration', 'averageDurationHistory']);
    this.convertPendingMetrics(data.pending);
    data.successRate = new SuccessRate(this.isBusinessExceptionSuccess, data.businessExceptionsCount, data.systemExceptionsCount, data.completedCount);
  }

  static convertPendingMetrics(data: PendingsMetrics): void {
    this.convertDates(data, ['oldestPendingItemDateTime']);
    this.convertTimeSpans(data, ['longestPendingTime']);
  }

  static convertProcessBasicInfo(data: ProcessBasicInfo): void {
    this.convertDates(data, ['from', 'to', 'uploadedOn']);
  }

  static convertMonitoringResource(data: MonitoringResource): void {
    if (data.lastActiveSession !== null) {
      this.convertDates(data.lastActiveSession, ['started', 'ended']);
    }
  }

  static convertItemsStatistics(data: ItemsStatistics): void {
    this.convertStatistics(ApiConversionHelper.objectValues(data.nodesStatistics));
    this.convertStatistics(ApiConversionHelper.objectValues(data.edgesStatistics));
  }

  static convertUtilization(data: LicensesUtilization | ResourcesUtilization): void {
    this.convertDates(data, ['timeStamps']);
  }

  static convertBucketedUtilization(data: BucketedUtilization): void {
    this.convertDates(data, ['from', 'to']);
    this.convertTimeSpans(data, ['bucketLength']);
    data.averageTotalCount = MathHelper.sum(data.totalCountsForDays, g => g / data.totalCountsForDays.length);
    const min = Math.min(...data.totalCountsForDays);
    const max = Math.max(...data.totalCountsForDays);
    data.totalCount = min === max ? min.toString() : `${min} - ${max}`;
    if (min !== max) {
      const lines: string[] = [];
      let indexFrom = 0;
      let previousValue = data.totalCountsForDays[0];
      for (let i = 1; i <= data.totalCountsForDays.length; i++) {
        const value = data.totalCountsForDays[i];
        if (value != previousValue) {
          const dateFrom = DateHelper.addDays(data.from, indexFrom);
          if (indexFrom === i - 1) {
            lines.push(`<b>${FormatHelper.format(dateFrom, FormatType.Date)}:</b> ${previousValue}`);
          } else {
            const dateTo = DateHelper.addDays(data.from, i - 1);
            lines.push(`<b>${DateHelper.formatDateRange(dateFrom, dateTo, DateRangeFormat.DateOnly)}:</b> ${previousValue}`);
          }
          indexFrom = i;
          previousValue = value;
        }
      }
      data.totalCountTooltip = lines.join('<br/>');
    }
  }

  static convertSchedule(schedule: Schedule): void {
    const segments = schedule.groups.flatMap(g => g.data).flatMap(d => d.data);
    this.convertDates(segments, ['timeRange', 'sessionStarted', 'sessionEnded']);
  }

  static convertSchedulerTaskConfiguration(configuration: SchedulerTaskConfiguration, onlyDates = false) {
    this.convertDates(configuration, ['onceDateTime']);
    if (onlyDates) {
      return;
    }

    this.convertTimeSpans(configuration, ['onceAPeriod', 'times', 'shouldStopAt', 'maxPendingTime']);
    this.convertTimeSpans(configuration?.timeWindows, ['from', 'to']);
  }

  static convertSchedulerTaskConfigurationForApi(configuration: SchedulerTaskConfiguration) {
    this.convertDateTimesForApi(configuration, ['onceDateTime']);
    this.convertTimeSpansForApi(configuration, ['onceAPeriod', 'maxPendingTime']);
    this.convertTimeForApi(configuration, ['times', 'shouldStopAt']);
    this.convertTimeForApi(configuration?.timeWindows, ['from', 'to']);
  }

  static convertIncidents(data: Incident[], addDayCount: number = null): void {
    this.convertDates(data, ['contextFrom', 'contextNow', 'contextTo'], addDayCount);
    const parts = data
      .flatMap(i => i.parts)
      .flatMap(p =>
        Object.keys(p)
          .map(k => (<any>p)[k])
          .filter(v => v != null),
      );
    this.convertDates(parts, ['partFrom', 'partTo', 'date'], addDayCount);
  }

  private static convertActionableInsights(data: ActionableInsight[]): void {
    data.forEach(ai => {
      ai.categories ??= [];
      ai.states ??= new Set();
      this.convertTimeSpans(ai, ['savedItemMeanDuration']);
      switch (ai.type) {
        case ActionableInsightType.ItemDurationTrend:
          const itemDurationTrendAi = <ItemDurationTrendActionableInsight>ai;
          this.convertDates(itemDurationTrendAi.days, ['date', 'averageEnds']);
          this.convertTimeSpans(itemDurationTrendAi.days, ['averageDurations']);
          break;
        case ActionableInsightType.Outliers:
          const outliersAi = <OutliersActionableInsight>ai;
          this.convertTimeSpans(outliersAi, ['medianDuration', 'minDuration', 'maxDuration', 'itemDurations']);
          this.convertTimeSpans(outliersAi.histogramAll, ['durations']);
          break;
        case ActionableInsightType.WaitOptimization:
          const waitOptimizationAi = <WaitOptimizationActionableInsight>ai;
          this.convertTimeSpans(waitOptimizationAi, ['meanDuration']);
      }
    });
  }

  private static convertVariants(data: Variant[]): void {
    const statistics = data.map(v => v.statistics);
    this.convertDates(statistics, ['firstOccurrence', 'lastOccurrence']);
    this.convertTimeSpans(statistics, ['meanDuration', 'minDuration', 'maxDuration', 'meanDurationHistory']);

    const nodeStatistics = data.flatMap(v => this.objectValues(v.statistics.nodesStatistics));
    this.convertStatistics(nodeStatistics);
    const edgeStatistics = data.flatMap(v => this.objectValues(v.statistics.edgesStatistics));
    this.convertStatistics(edgeStatistics);
  }

  private static convertStatistics(data: (NodeStatistics | EdgeStatistics | ProcessStatistics)[]): void {
    const definedStatistics = data.filter(s => s != null);
    this.convertTimeSpans(definedStatistics, ['meanDuration', 'minDuration', 'maxDuration', 'totalDuration']);

    const processStatistics = definedStatistics.filter(s => Object.prototype.hasOwnProperty.call(s, 'meanDurationHistory')).map(s => <ProcessStatistics>s);
    this.convertTimeSpans(processStatistics, ['meanDurationHistory']);
  }

  static convertDates<T>(input: T | T[], keys: (keyof T)[], addDayCount: number = null): void {
    this.convert<T, string, Date>(input, keys, 'string', value => {
      const date = DateHelper.parseApiDate(value);
      return addDayCount != null ? DateHelper.addDays(date, addDayCount) : date;
    });
  }

  static convertDateTimesForApi<T>(input: T | T[], keys: (keyof T)[]): void {
    this.convert<T, Date, string>(input, keys, 'Date', value => DateHelper.formatApiDateTime(value));
  }

  static convertTimeSpans<T>(input: T | T[], keys: (keyof T)[]): void {
    return this.convert<T, string, number>(input, keys, 'string', value => DateHelper.parseDuration(value));
  }

  static convertTimeSpansForApi<T>(input: T | T[], keys: (keyof T)[]): void {
    return this.convert<T, number, number>(input, keys, 'number', value => DateHelper.formatDuration(value));
  }

  static convertTimeForApi<T>(input: T | T[], keys: (keyof T)[]): void {
    if (input == null) {
      return;
    }
    return this.convert<T, number, string>(input, keys, 'number', value => DateHelper.formatTime(value));
  }

  // iterate recursively data.flameGraphRoot all meanDuration properties and convert them to time spans
  static convertTimeSpansRecursive<T>(input: T, recursionParamKey: string, keys: (keyof T)[]): void {
    this.convertTimeSpans(input, keys);
    if (Object.prototype.hasOwnProperty.call(input, recursionParamKey)) {
      const children = input[recursionParamKey as keyof T];
      if (children != null && Array.isArray(children)) {
        children.forEach(c => this.convertTimeSpansRecursive(c, recursionParamKey, keys));
      }
    }
  }

  private static convert<TObject, TFrom, TTo>(input: TObject | TObject[], keys: (keyof TObject)[], fromTypeName: string, convert: (value: TFrom) => TTo): void {
    if (input == null) {
      return;
    }

    const objArray = Array.isArray(input) ? input : [input];

    objArray
      .filter(o => o != null)
      .forEach((obj: unknown) => {
        for (const key of keys) {
          const value = obj[key];
          if (this.isOfType(value, fromTypeName)) {
            obj[key] = convert(<TFrom>value);
            continue;
          }

          if (Array.isArray(value)) {
            if (value.length === 0) {
              continue;
            }

            if (value.every(v => v == null || this.isOfType(v, fromTypeName))) {
              obj[key] = value.map(v => convert(<TFrom>v));
              continue;
            }
          }

          if (value == null) {
            continue;
          }

          console.warn(`Invalid conversion: key ${String(key)} is not of type ${fromTypeName} or ${fromTypeName}[].`);
        }
      });
  }

  private static isOfType(value: unknown, typeName: string): boolean {
    const type = typeof value;
    if (type === typeName) {
      return true;
    }

    if (type === 'object' && Object.prototype.toString.call(value) === `[object ${typeName}]`) {
      return true;
    }
    return false;
  }

  static objectValues<T>(o: { [s: string]: T } | ArrayLike<T>): T[] {
    if (o == null) {
      return [];
    }
    return Object.values(o);
  }

  static convertToString(value: any) {
    return value != null ? `"${value}"` : null;
  }
}
