import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  TimeScale,
  PieController,
  ArcElement,
  ScatterController,
  BubbleController,
  PointElement,
  LineController,
  LineElement,
  Filler,
  DoughnutController,
  Title,
} from 'chart.js';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
import ChartJsAnnotation from 'chartjs-plugin-annotation';
import { ChartColor } from './chart-color.enum';
import 'chartjs-adapter-luxon';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class ChartsModule {
  constructor() {
    Chart.defaults.color = ChartColor.Font;
    Chart.register(
      BarController,
      BubbleController,
      DoughnutController,
      LineController,
      PieController,
      ScatterController,
      ArcElement,
      BarElement,
      LineElement,
      PointElement,
      LinearScale,
      CategoryScale,
      TimeScale,
      Title,
      Legend,
      Tooltip,
      Filler,
      ChartJsAnnotation,
      MatrixController,
      MatrixElement,
    );
  }
}
