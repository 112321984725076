import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, map } from 'rxjs';
import { ChangePasswordModalComponent } from 'src/app/shared/components/_modals/change-password-modal/change-password-modal.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LoadingScreenService } from 'src/app/shared/services/loading-screen.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { OrganizationService } from 'src/app/shared/services/organization.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Global } from 'src/app/shared/types/global.type';
import { LoggedUser } from 'src/app/shared/types/logged-user.type';
import { MenuService } from '../../shared/services/menu.service';
import { MenuSection } from '../../shared/types/menu-section.type';
import { OrganizationSelectComponent } from '../organization-select/organization-select.component';
import { NotificationSettingsModalComponent } from '../../shared/components/_modals/notification-settings-modal/notification-settings-modal.component';
import { FeatureFlags } from 'src/app/shared/enums/generated.enums';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements AfterViewInit {
  menuSections$: Observable<MenuSection[]> = this.menuService.menuSections$;
  user: LoggedUser;
  isMobile = false;
  isScrolled = false;
  isNextScrolled = false;
  shouldExpandMenu = true;
  private displayScrollbar = false;
  readonly minScroll = 8;

  get parentRoute$(): Observable<string[] | null> {
    return this.menuService.menuSections$.pipe(map(menuSections => menuSections[0]?.parentRoute));
  }

  get firstLabel(): Observable<string | null> {
    return this.menuService.menuSections$.pipe(map(menuSections => menuSections[0]?.label));
  }

  get rootRoute(): string[] {
    return this.navigationService.getRootRoute();
  }

  get isMenuExpanded(): boolean {
    return this.storageService.isMenuExpanded;
  }

  set isMenuExpanded(expanded: boolean) {
    this.storageService.isMenuExpanded = expanded;
  }

  get allowOrganizationSelect(): boolean {
    // depends on the number of organizations for the user
    return this.organizationService.organizations?.length > 1;
  }

  get isUserPasswordLoginEnabled(): boolean {
    return this.organizationService.organizationConfiguration?.isUserPasswordLoginDisabled != true;
  }

  get isSystemAdmin(): boolean {
    return this.authService.isSystemAdmin;
  }

  get areNotificationSettingsEnabled(): boolean {
    return this.authService.hasFeatureFlag(FeatureFlags.Alerting);
  }

  @ViewChild(MatMenuTrigger, { static: false }) profileMenu: MatMenuTrigger;
  @ViewChild('menuBodyContainer', { static: true }) menuBodyContainer: ElementRef<HTMLElement>;

  constructor(
    private menuService: MenuService,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private navigationService: NavigationService,
    private storageService: StorageService,
    private organizationService: OrganizationService,
    private loadingScreenService: LoadingScreenService,
  ) {
    this.user = this.authService.user;

    // mobile menu toggle
    this.isMobile = window.innerWidth < Global.mobileBreakpoint;
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < Global.mobileBreakpoint;
    });
  }

  ngAfterViewInit(): void {
    // check if the menu is scrollable and set the shadow for footer
    this.isNextScrolled = this.menuBodyContainer.nativeElement.offsetHeight < this.menuBodyContainer.nativeElement.children[0].scrollHeight - this.minScroll;

    this.menuSections$.subscribe(menuSections => {
      // count the number of items in the "items" type sections
      const itemCount = menuSections
        .filter(section => section.type === 'items' || section.type === 'submenu')
        .map(section => section.items.length)
        .reduce((a, b) => a + b, 0);

      if (itemCount > 0 && itemCount < 3) {
        this.isMenuExpanded = false;
        this.shouldExpandMenu = false;
      } else {
        this.shouldExpandMenu = true;
      }
    });
  }

  public toggleMenuExpanded() {
    this.cdr.markForCheck();
    this.isMenuExpanded = !this.isMenuExpanded;
    if (!this.shouldExpandMenu) {
      this.isMenuExpanded = false;
    }
  }

  onBodyContainerScroll(event: Event): void {
    const target = event.target as HTMLElement;
    // header scroll shadow
    this.isScrolled = target.scrollTop > this.minScroll;
    // footer scroll shadow
    this.isNextScrolled = target.offsetHeight + target.scrollTop <= target.scrollHeight - this.minScroll;
    if (this.isScrolled !== this.displayScrollbar) {
      this.displayScrollbar = this.isScrolled;
    }
  }

  // display modals and menus
  public displayOrganizationSelect() {
    this.modalService.open(OrganizationSelectComponent);
  }

  public changePassword(): void {
    this.modalService.open(ChangePasswordModalComponent);
  }

  async signOut(): Promise<void> {
    await this.navigationService.navigateToLogin('User logged out manually', false, false);
  }

  async gotoDesignSystemOverview(): Promise<void> {
    await this.navigationService.navigateToDesignSystemOverview();
  }

  async gotoAdministrationPage(): Promise<void> {
    await this.navigationService.navigateToAdminOrganization(this.organizationService.selectedOrganization?.name ?? '');
  }

  async reloadPage(): Promise<void> {
    // wait to nvaiagte to login page
    this.loadingScreenService.showLoadingScreen();
    setTimeout(() => {
      window.location.reload();
      this.loadingScreenService.hideLoadingScreen();
    }, 100);
  }

  openNotificationSettings() {
    this.modalService.open(NotificationSettingsModalComponent);
  }
}
