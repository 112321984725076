import { FormatType } from '../enums/format-type.enum';
import { LabelIcon } from '../enums/label-icon.enum';
import { LabelTextClass } from '../enums/label-text-class.enum';
import { FormatHelper } from '../helpers/format.helper';

export class Label {
  text: string;
  shouldBeIgnored = false;
  constructor(public value: number, public type: FormatType, public icon: LabelIcon, public textClass: LabelTextClass = LabelTextClass.Normal, public ignoreZero = false) {
    this.text = FormatHelper.format(value, type);
    this.shouldBeIgnored = ignoreZero && (type === FormatType.Percentage ? value < 0.0005 : type === FormatType.Time ? value < 50 : value < 0.05);
  }
}
