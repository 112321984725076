import { Component } from '@angular/core';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  constructor(private navigationService: NavigationService) {}

  public async navigateToDefaultPage() {
    await this.navigationService.navigateToDefaultPage(true);
  }
}
