import { Injectable } from '@angular/core';
import { DateHelper } from '../../helpers/date.helper';
import { ReportDescription } from '../../types/report-description.type';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class ReportsApiService extends BaseApiService {
  protected get baseUrl(): string {
    return `${super.baseUrl}/reports`;
  }

  public async getReportMonths(): Promise<Date[]> {
    return (await this.get<string[]>('months')).map(d => DateHelper.parseApiDate(d));
  }

  public async getReports(date: Date): Promise<ReportDescription[]> {
    return await this.get<ReportDescription[]>(DateHelper.formatApiDateOnly(date));
  }

  downloadReportsAll(date: Date): void {
    this.download(`actions/download-all/${DateHelper.formatApiDateOnly(date)}`);
  }

  downloadReportPdf(id: string): void {
    this.download(`actions/download-pdf/${id}`);
  }
}
