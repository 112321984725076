import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { BaseModalDialogDirective } from '../../../directives/base-modal-dialog.directive';
import { AlertSeverity } from '../../../enums/generated.enums';
import { ModalSize } from '../../../enums/modal-size.enum';
import { UserConfiguration, UserNotificationConfiguration } from '../../../types/user-configuration.type';
import { ModalCloseType } from '../../modal/modal-close-type.enum';

@Component({
  selector: 'app-notification-settings-modal',
  templateUrl: './notification-settings-modal.component.html',
  styleUrls: ['./notification-settings-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationSettingsModalComponent extends BaseModalDialogDirective implements OnInit {
  readonly columnsToDisplay = ['type', 'in-app', 'email'];

  private readonly defaultNotifications: UserNotificationConfiguration[] = [
    {
      severity: AlertSeverity.Info,
      inAppEnabled: true,
      emailEnabled: false,
    },
    {
      severity: AlertSeverity.Warning,
      inAppEnabled: true,
      emailEnabled: false,
    },
    {
      severity: AlertSeverity.Error,
      inAppEnabled: true,
      emailEnabled: true,
    },
  ];

  configuration: UserConfiguration;

  get size(): ModalSize {
    return ModalSize.Medium;
  }

  get closeType(): ModalCloseType {
    return ModalCloseType.Safe;
  }

  constructor(private apiService: ApiService, private cdr: ChangeDetectorRef) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.configuration = await this.apiService.userConfiguration.getConfiguration();
    this.configuration ??= { notifications: this.defaultNotifications };
    this.cdr.markForCheck();
  }

  async save(): Promise<void> {
    try {
      await this.apiService.userConfiguration.updateConfiguration(this.configuration);
      this.close();
    } catch (error) {
      console.error(error);
    }
  }
}
