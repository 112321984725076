import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FormatType } from '../enums/format-type.enum';
import { LabelIcon } from '../enums/label-icon.enum';
import { LabelTextClass } from '../enums/label-text-class.enum';
import { ProcessMapMetric } from '../enums/process-map-metric.enum';
import { Edge } from '../types/edge.type';
import { Label } from '../types/label.type';
import { Node } from '../types/node.type';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class ProcessViewService {
  isPlaying = false;
  transferPlayTick: Subject<number> = new Subject();
  highlightedNodeIds: number[] = [];
  get selectedNodeEdgeId(): string {
    return this.storageService.selectedNodeEdgeId;
  }

  constructor(private storageService: StorageService) {}

  initialize(): void {
    const selectedItemId = this.storageService.selectedNodeEdgeId;
    if (selectedItemId == null) {
      return;
    }
    this.selectItem(selectedItemId);
  }

  getNodeLabels(node: Node): Label[] {
    const labels: Label[] = [];
    if (this.isPlaying) {
      const label = new Label(node.replayLogsCount, FormatType.Number, LabelIcon.Count);
      labels.push(label);
    } else {
      if (this.storageService.hasMetric(ProcessMapMetric.LogsCount)) {
        labels.push(this.getNodeLogsCount(node));
      }
      if (this.storageService.hasMetric(ProcessMapMetric.CasesCount)) {
        labels.push(this.getCasesCount(node));
      }
      if (this.storageService.hasMetric(ProcessMapMetric.CasesCountPercentage)) {
        labels.push(this.getCasesCountPercentage(node));
      }
      if (this.storageService.hasMetric(ProcessMapMetric.MeanDuration)) {
        labels.push(this.getMeanDuration(node));
      }
      if (this.storageService.hasMetric(ProcessMapMetric.MeanDurationPercentage)) {
        labels.push(this.getMeanDurationPercentage(node));
      }
      if (this.storageService.hasMetric(ProcessMapMetric.AveragePrice)) {
        labels.push(this.getAveragePrice(node));
      }
      if (this.storageService.hasMetric(ProcessMapMetric.AveragePricePercentage)) {
        labels.push(this.getAveragePricePercentage(node));
      }
      if (this.storageService.hasMetric(ProcessMapMetric.TotalYearCosts)) {
        labels.push(this.getTotalYearCosts(node));
      }
    }
    return labels;
  }

  getEdgeLabels(edge: Edge): Label[] {
    const labels: Label[] = [];
    if (this.isPlaying) {
      const label = new Label(edge.replayTransfersCount, FormatType.Number, LabelIcon.Count);
      labels.push(label);
    } else {
      if (this.storageService.hasMetric(ProcessMapMetric.LogsCount)) {
        labels.push(this.getEdgeTransfersCount(edge));
      }
      if (this.storageService.hasMetric(ProcessMapMetric.CasesCount)) {
        labels.push(this.getCasesCount(edge));
      }
      if (this.storageService.hasMetric(ProcessMapMetric.CasesCountPercentage)) {
        labels.push(this.getCasesCountPercentage(edge));
      }
      if (this.storageService.hasMetric(ProcessMapMetric.MeanDuration)) {
        labels.push(this.getMeanDuration(edge, true));
      }
      if (this.storageService.hasMetric(ProcessMapMetric.MeanDurationPercentage)) {
        labels.push(this.getMeanDurationPercentage(edge, true));
      }
      if (this.storageService.hasMetric(ProcessMapMetric.AveragePrice)) {
        labels.push(this.getAveragePrice(edge, true));
      }
      if (this.storageService.hasMetric(ProcessMapMetric.AveragePricePercentage)) {
        labels.push(this.getAveragePricePercentage(edge, true));
      }
      if (this.storageService.hasMetric(ProcessMapMetric.TotalYearCosts)) {
        labels.push(this.getTotalYearCosts(edge, true));
      }
    }
    return labels.filter(l => !l.shouldBeIgnored);
  }

  selectItem(selectedItemId?: string): void {
    this.storageService.selectedNodeEdgeId = selectedItemId;
  }

  clearSelection(): void {
    this.storageService.selectedNodeEdgeId = null;
  }

  protected getNodeLogsCount(node: Node): Label {
    return new Label(node.statistics.logsCount, FormatType.Number, LabelIcon.Count);
  }

  protected getEdgeTransfersCount(edge: Edge): Label {
    return new Label(edge.statistics.transfersCount, FormatType.Number, LabelIcon.Count);
  }

  protected getCasesCount(item: Node | Edge): Label {
    return new Label(item.statistics.casesCount, FormatType.Number, LabelIcon.Count);
  }

  protected getCasesCountPercentage(item: Node | Edge): Label {
    return new Label(item.statistics.casesCountPercentage, FormatType.Percentage, LabelIcon.Count);
  }

  protected getNodeReplayLogsCount(node: Node): Label {
    return new Label(node.replayLogsCount, FormatType.Number, LabelIcon.Count);
  }

  protected getEdgeReplayTransfersCount(edge: Edge): Label {
    return new Label(edge.replayTransfersCount, FormatType.Number, LabelIcon.Count);
  }

  protected getMeanDuration(item: Node | Edge, shouldBeIgnored?: boolean): Label {
    const isHigherNorm = item instanceof Node && item.normDuration ? item.statistics.meanDuration > item.normDuration : false;
    return new Label(item.statistics.meanDuration, FormatType.Time, LabelIcon.Duration, isHigherNorm ? LabelTextClass.Deviated : LabelTextClass.Normal, shouldBeIgnored);
  }

  protected getMeanDurationPercentage(item: Node | Edge, shouldBeIgnored?: boolean): Label {
    const isHigherNorm = item instanceof Node && item.normDuration ? item.statistics.meanDuration > item.normDuration : false;
    return new Label(
      item.statistics.meanDurationPercentage,
      FormatType.Percentage,
      LabelIcon.Duration,
      isHigherNorm ? LabelTextClass.Deviated : LabelTextClass.Normal,
      shouldBeIgnored,
    );
  }

  protected getAveragePrice(item: Node | Edge, shouldBeIgnored?: boolean): Label {
    return new Label(item.statistics.averagePrice, FormatType.Currency, LabelIcon.Price, LabelTextClass.Normal, shouldBeIgnored);
  }

  protected getAveragePricePercentage(item: Node | Edge, shouldBeIgnored?: boolean): Label {
    return new Label(item.statistics.averagePricePercentage, FormatType.Percentage, LabelIcon.Price, LabelTextClass.Normal, shouldBeIgnored);
  }

  protected getTotalYearCosts(item: Node | Edge, shouldBeIgnored?: boolean): Label {
    return new Label(item.statistics.totalYearCosts, FormatType.Currency, LabelIcon.Price, LabelTextClass.Normal, shouldBeIgnored);
  }
}
