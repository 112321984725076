import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { FeatureFlags, LoginType, Permission } from '../enums/generated.enums';
import { LoggedUser } from '../types/logged-user.type';
import { ApiService } from './api/api.service';
import { LoggingService } from './logging.service';
import { MsalService } from './msal.service';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private userCookieName = 'User';
  private _user: LoggedUser;
  private refreshTokenTimeoutId: ReturnType<typeof setTimeout>;
  private refreshTokenRequest: Promise<boolean>;

  get user(): LoggedUser {
    return this._user;
  }

  get isLoggedIn(): boolean {
    return this._user != null;
  }

  get isSystemAdmin(): boolean {
    return this.hasPermission(Permission.SystemAdmin);
  }

  private get loginType(): LoginType {
    return this.storageService.loginType ?? LoginType.Password;
  }

  constructor(private loggingService: LoggingService, private apiService: ApiService, private msalService: MsalService, private cookieService: CookieService, private storageService: StorageService) {
    this.initializeTokens();
  }

  private initializeTokens() {
    const userJson = this.cookieService.get(this.userCookieName);
    if (userJson) {
      this._user = JSON.parse(userJson) as LoggedUser;
      this.loggingService.setAuthenticatedUser(this._user.id, this._user.organization, this.isSystemAdmin);
      this.startTokenRefresh();
    } else {
      this._user = null;
      this.loggingService.clearAuthenticatedUser();
      this.stopTokenRefresh();
    }
  }

  async loginWithPassword(email: string, password: string): Promise<boolean> {
    await this.apiService.login(email, password);
    this.initializeTokens();
    this.storageService.loginType = LoginType.Password;
    return true;
  }

  async loginWithMicrosoft(): Promise<boolean> {
    try {
      const token = await this.msalService.login();
      if (token != null) {
        await this.apiService.loginWithMicrosoft(token);
        this.initializeTokens();
        this.storageService.loginType = LoginType.Microsoft;
        return true;
      }
      return false;
    } catch (error) {
      this.loggingService.logError('Failed to login with Microsoft', error);
      throw error;
    }
  }

  async logout(): Promise<void> {
    if (this.loginType === LoginType.Microsoft) {
      await this.msalService.logout();
    }
    await this.apiService.logout();
    this.initializeTokens();
  }

  async refreshToken(): Promise<boolean> {
    if (this.refreshTokenRequest == null) {
      try {
        this.refreshTokenRequest = this.refreshTokenInternal();
        return await this.refreshTokenRequest;
      } catch (error) {
        this.loggingService.logError('Failed to refresh token', error);
        return false;
      } finally {
        this.refreshTokenRequest = null;
      }
    }

    return await this.refreshTokenRequest;
  }

  private async refreshTokenInternal(): Promise<boolean> {
    if (this.loginType === LoginType.Microsoft) {
      const token = await this.msalService.acquireToken();
      await this.apiService.loginWithMicrosoft(token);
    } else {
      await this.apiService.refreshToken();
    }

    this.initializeTokens();
    return true;
  }

  async setOrganization(organizationName: string) {
    try {
      if (this.loginType === LoginType.Microsoft) {
        const token = await this.msalService.acquireToken();
        await this.apiService.loginWithMicrosoft(token, organizationName);
      } else {
        await this.apiService.setOrganization(organizationName);
      }
      this.initializeTokens();
    } catch (error) {
      this.loggingService.logError('Failed to set organization', error);
      throw error;
    }
  }

  hasPermission(permission: Permission): boolean {
    return this._user != null && this._user.permissions != null && this._user.permissions.includes(permission);
  }

  hasFeatureFlag(featureFlag: FeatureFlags): boolean {
    return this._user != null && this._user.featureFlags != null && this._user.featureFlags.includes(featureFlag);
  }

  private startTokenRefresh(): void {
    this.stopTokenRefresh();
    this.refreshTokenTimeoutId = setTimeout(async () => await this.refreshToken(), 600000); // 10 minutes
  }

  private stopTokenRefresh(): void {
    if (this.refreshTokenTimeoutId) {
      clearTimeout(this.refreshTokenTimeoutId);
      this.refreshTokenTimeoutId = null;
    }
  }
}
