import { DateHelper } from '../helpers/date.helper';
import { DateRange } from './date-range.type';

export class DataAvailabilityInfo {
  readonly firstDate: Date;
  readonly lastDate: Date;
  readonly isValid: boolean;

  constructor(public readonly datesWithData: DateRange[], isValid: boolean) {
    if (datesWithData.length > 0) {
      this.firstDate = DateHelper.startOfDay(datesWithData[0].start);
      this.lastDate = DateHelper.startOfDay(datesWithData[datesWithData.length - 1].end);
    }

    this.isValid = isValid;
  }

  hasDataForDate(date: Date): boolean {
    if (!this.isValid) {
      return false;
    }

    if (this.datesWithData != null) {
      return this.datesWithData.some(x => date >= x.start && date <= x.end);
    }

    return false;
  }

  hasAnyDataForInterval(range: DateRange): boolean {
    if (!this.isValid) {
      return false;
    }

    return this.datesWithData.some(x => x.overlaps(range));
  }

  reduceIntervalToAvailableData(range: DateRange): DateRange {
    if (!this.isValid) {
      return range;
    }

    const start = range.start < this.firstDate ? this.firstDate : range.start;
    const end = range.end > this.lastDate ? this.lastDate : range.end;

    return new DateRange(start, end);
  }
}
