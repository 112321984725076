import { Directive, ViewChild } from '@angular/core';
import { ModalCloseType } from '../components/modal/modal-close-type.enum';
import { ModalComponent } from '../components/modal/modal.component';
import { ModalSize } from '../enums/modal-size.enum';

@Directive()
export abstract class BaseModalDialogDirective {
  @ViewChild(ModalComponent, { static: true }) set modal(modal: ModalComponent) {
    this._modal = modal;
  }

  get size(): ModalSize {
    return ModalSize.Large;
  }

  get closeType(): ModalCloseType {
    return ModalCloseType.Normal;
  }

  private _modal: ModalComponent;

  close(result?: any): void {
    this._modal.close(result);
  }
}
