<shared-modal>
  <div modalBody class="data-error-wrapper">
    <div class="error-content">
      <div class="error-content-header">
        <svg-icon key="server-error-500"></svg-icon>
        <h3 class="no-margin">Sorry, something went wrong</h3>
      </div>

      <p class="no-margin">The server encountered an error and could not complete your request.</p>
      <button class="btn btn-link" (click)="reloadCurrentPage()">
        <span>Try again</span>
      </button>
    </div>
  </div>
</shared-modal>
