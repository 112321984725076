<shared-modal [modalTitle]="'Tenants'" [showFooter]="false">
  <div class="nav-item-menu-item select-list-item" *ngIf="organizations.length >= 10" modalHeader>
    <app-search-input (search)="applyFilter($event)"></app-search-input>
  </div>

  <ng-container modalBody>
    <ul *ngIf="organizations" class="scroll-list">
      <li
        *ngFor="let organization of organizations | appFilter: organizationFilter: 'label'"
        (click)="selectOrganization(organization.name)"
        class="organization-select-item"
        [ngClass]="{ selected: isSelected(organization.name) }"
      >
        {{ organization.label }}
      </li>
    </ul>
    <div *ngIf="!organizations">
      <p>There are no tenants</p>
    </div>
  </ng-container>
</shared-modal>
