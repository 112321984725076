import { DateHelper, DateRangeFormat } from '../helpers/date.helper';

export class DateRange {
  private _start: Date;
  private _end: Date;

  get start(): Date {
    return this._start;
  }

  get end(): Date {
    return this._end;
  }

  constructor(start: Date, end?: Date) {
    this._start = start;
    this._end = end ?? start;
  }

  static fromMs(start: number, end?: number): DateRange {
    return new DateRange(new Date(start), end ? new Date(end) : null);
  }

  equals(other: DateRange): boolean {
    if (other == null) {
      return false;
    }
    // Compare dates by value, not by reference is important here, becase this.start === other.start will always be false
    return this.start.getTime() === other.start.getTime() && this.end.getTime() === other.end.getTime();
  }

  clone(): DateRange {
    return new DateRange(new Date(this.start), new Date(this.end));
  }

  overlaps(other: DateRange): boolean {
    if (other == null) {
      return false;
    }
    return this.end >= other.start && this.start <= other.end;
  }

  contains(date: Date): boolean {
    return this.start <= date && this.end >= date;
  }

  getDayCount(): number {
    return DateHelper.getDaysCount(this.start, this.end);
  }

  representsDate(date: Date): boolean {
    return this.representsSingleDay() && DateHelper.isSameDay(this.start, date);
  }

  representsSingleDay(): boolean {
    if (this.start == null || this.end == null) {
      return false;
    }
    return DateHelper.isSameDay(this.start, this.end);
  }

  format(format: DateRangeFormat): string {
    return DateHelper.formatDateRange(this.start, this.end, format);
  }

  duration(): number {
    return this.end.getTime() - this.start.getTime();
  }

  isValid(): boolean {
    try {
      return this.start != null && this.end != null && !isNaN(this.start.getTime()) && !isNaN(this.end.getTime()) && this.start <= this.end;
    } catch {
      return false;
    }
  }
}
