import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggingService } from '../services/logging.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {
    window.onerror = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {
      this.onWindowError(event, source, lineno, colno, error);
    };
  }

  onWindowError(event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) {
    if (this.shouldIgnore(event)) {
      return;
    }
    const loggingService = this.injector.get(LoggingService);
    loggingService.logWindowError(event, source, lineno, colno, error);
  }

  async handleError(error: Error) {
    const loggingService = this.injector.get(LoggingService);
    loggingService.logException(error);
  }

  private shouldIgnore(event: Event | string): boolean {
    return event === 'ResizeObserver loop limit exceeded';
  }
}
