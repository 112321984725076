import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

export enum PreventFocusMode {
  Always = 'Always',
  OnceAfterClick = 'OnceAfterClick',
}

@Directive({
  selector: '[preventFocus]',
})
export class PreventFocusDirective implements OnInit {
  @Input('preventFocus') mode: string = PreventFocusMode.Always;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (this.mode === PreventFocusMode.Always || this.mode == null) {
      this.el.nativeElement.addEventListener('focus', (e: FocusEvent) => {
        (e.target as HTMLElement)?.blur();
      });
    }
  }

  @HostListener('click', ['$event'])
  onClick(): void {
    if (this.mode !== PreventFocusMode.OnceAfterClick) {
      return;
    }

    this.el.nativeElement.addEventListener(
      'focus',
      (e: FocusEvent) => {
        e.preventDefault();
        (e.target as HTMLElement)?.blur();
      },
      { once: true },
    );
  }
}
