import { Platform } from 'src/app/shared/enums/generated.enums';
import { TextHelper } from 'src/app/shared/helpers/text.helper';

export class PlatformSpecifics {
  acceptedFileTypes: string;
  logFileTypesHtml: string;
  logFileTypesText: string;

  get isBluePrism(): boolean {
    return this.platform === Platform.BluePrism;
  }

  get isUiPath(): boolean {
    return this.platform === Platform.UiPath;
  }

  constructor(private platform: Platform, public label: string, public logFileTypes: string[]) {
    if (logFileTypes == null) {
      return;
    }
    this.logFileTypesHtml = TextHelper.joinWithCommaAndOr(logFileTypes.map(t => `<b>*${encodeURIComponent(t)}</b>`));
    logFileTypes.push('.zip');
    this.acceptedFileTypes = logFileTypes.join(',');
    this.logFileTypesText = TextHelper.joinWithCommaAndOr(logFileTypes.map(t => `*${t}`));
  }
}
