import { Component, Input } from '@angular/core';
import { SVG_CONFIG } from '@ngneat/svg-icon/lib/types';

@Component({
  selector: 'shared-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss'],
  host: { class: 'd-inline-flex' },
})
export class InfoIconComponent {
  private _infoIconKey = 'information-circle';

  get infoIconKey(): string {
    return this._infoIconKey;
  }
  @Input() set infoIconKey(value: string) {
    if (!value) {
      return;
    }
    this._infoIconKey = value;
  }
  @Input() size: keyof SVG_CONFIG['sizes'] = 'sm';
  @Input() color: string;

  // if color is not provided, then use the default color
  get colorClass(): string {
    return this.color ? this.color : 'color-primary';
  }
}
