import { Injectable } from '@angular/core';
import { Observable, Subject, filter } from 'rxjs';

export enum EventType {
  OrganizationChanged,
}

@Injectable({ providedIn: 'root' })
export class EventBusService {
  private events$ = new Subject<{ type: EventType; data?: any }>();

  emit(type: EventType, data?: any) {
    this.events$.next({ type, data });
  }

  on(type: EventType): Observable<any> {
    return this.events$.pipe(filter(e => e.type === type));
  }
}
