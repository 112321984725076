import { TextConstants } from '../text-constants';

export class TextHelper {
  static canvas: HTMLCanvasElement;

  static removeDiacritics(value: string): string {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  static getTextWidth(text: string, font?: string, margin = 8): number {
    if (!text) {
      return 0;
    }
    const canvas = TextHelper.canvas || (TextHelper.canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    context.font = font || 'normal 14px sans-serif';
    const metrics = context.measureText(text);
    return metrics.width + 2 * margin + 4;
  }

  // https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript
  // get element's font style and size and return it as a string -> to be used for getTextWidth method
  static getCanvasFont(el = document.body) {
    function getCssStyle(el: HTMLElement, style: string) {
      return window.getComputedStyle(el, null).getPropertyValue(style);
    }
    const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
    const fontSize = getCssStyle(el, 'font-size') || '14x';
    const fontFamily = getCssStyle(el, 'font-family') || 'sans-serif';

    return `${fontWeight} ${fontSize} ${fontFamily}`;
  }

  // https://www.geeksforgeeks.org/string-hashing-using-polynomial-rolling-hash-function/
  static polynomialRollingHash(str: string): number {
    const p = 31;
    const m = 1e9 + 9;
    let powerOfP = 1;
    let hashVal = 0;
    for (let i = 0; i < str.length; i++) {
      hashVal = (hashVal + (str.charCodeAt(i) - 'a'.charCodeAt(0) + 1) * powerOfP) % m;
      powerOfP = (powerOfP * p) % m;
    }
    return hashVal;
  }

  // shorten text and add '...' at the end of shortening text
  static textEllipsis(str: string, length: number): string {
    if (str == null) {
      return null;
    }
    if (!length || str.length <= length) {
      return str;
    }
    const indexN = str.indexOf('\n');
    length = indexN > -1 && indexN < length ? indexN : length;
    const indexR = str.indexOf('\r');
    length = indexR > -1 && indexR < length ? indexR : length;
    return str.substring(0, length).concat('...');
  }

  static toPascalCase(kebebCase: string): string {
    if (!kebebCase) {
      return kebebCase;
    }
    const parts = kebebCase.split('-');
    const pascalCase = parts.map(p => p.charAt(0).toUpperCase() + p.slice(1).toLowerCase()).join('');
    return pascalCase;
  }

  static getUrlName(name: string): string {
    return name
      .replace(/([A-Z])/g, (a: string, b: string) => `-${b}`)
      .replace(/([A-Z])-([A-Z])-([A-Z])/g, (a: string, b: string, c: string, d: string) => `${b}${c}-${d}`)
      .substring(1)
      .toLowerCase();
  }

  static firstLetterToUpperCase(text: string) {
    return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
  }

  static joinWithCommaAndOr(texts: string[]) {
    const parts: string[] = [];
    texts.forEach((t, i) => (parts[i * 2] = t));
    texts.filter((t, i) => i < texts.length - 1).forEach((t, i) => (parts[i * 2 + 1] = i < texts.length - 2 ? ', ' : ' or '));
    return parts.join('');
  }

  static convertSpacesToNbsp(value: string): string {
    return value.replace(/\s/g, '\u00A0');
  }

  static convertNbspToSpaces(value: string): string {
    return value.replace('\u00A0', ' ');
  }

  static getTextInRightForm(count: number, text: string, textInPlural: string = null): string {
    return count === 1 ? text : textInPlural ?? `${text}s`;
  }

  static getTextWithCount(count: number, text: string, textInPlural: string = null, includeCount = true): string {
    const validTextForm = this.getTextInRightForm(count, text, textInPlural);
    return includeCount ? `${count} ${validTextForm}` : validTextForm;
  }

  static enumToText(value: string): string {
    if (value == null) {
      return null;
    }
    if (value === TextConstants.notAvailable) {
      return TextConstants.notAvailable;
    }
    const parts = value.split(/(?=[A-Z])/);
    return this.firstLetterToUpperCase(parts.join(' ').toLowerCase());
  }

  static splitText(text: string, maxLength: number): string[] {
    const lines: string[] = [];
    while (text.length > 0) {
      const index = text.indexOf(' ', maxLength);
      if (index === -1) {
        lines.push(text);
        break;
      }

      lines.push(text.substring(0, index));
      text = text.substring(index + 1);
    }

    return lines;
  }

  static removeHtmlTags(text: string): string {
    return text?.replace(/<[^>]*>/g, '');
  }
}
