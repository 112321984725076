<div class="upload-processing-wrapper in" *ngIf="isOpen" [ngClass]="{ collapsed: isCollapsed, 'right-gap': isProcessMap }">
  <div class="dialog-content">
    <div class="dialog-header">
      <div class="title">{{ dialogTitle }}</div>

      <button type="button" class="btn btn-icon-only dialog-collapse dialog-header-button" [tooltip]="collapseTooltip" (click)="toggleCollapse()">
        <svg-icon key="chevron-down"></svg-icon>
      </button>

      <button type="button" class="btn btn-icon-only dialog-header-button" [tooltip]="closeTooltip" (click)="close()">
        <svg-icon key="header-close"></svg-icon>
      </button>
    </div>

    <div class="dialog-body">
      <div class="upload-processing-body-content" *ngFor="let item of items; trackBy: trackById">
        <div
          class="upload-process-item"
          [tooltip]="item.fileName"
          [tooltipDisplayTarget]="item.id"
          [showDelay]="500"
          (click)="displayResult(item)"
          [ngClass]="{
            'success': item.isSuccessful || item.isDeletedSuccessfully || item.isUploaded,
            'error': item.isError,
            'cancelled': item.isCancelled,
            'deleted': item.isDeleted
          }"
        >
          <ng-container *ngIf="item.isUploading || item.isPending || item.isProcessing">
            <app-processing-progress-circle [status]="item.status" [totalProgress]="item.totalProgress"></app-processing-progress-circle>
            <div class="upload-info">
              <div [attr.id]="item.id" class="upload-file">
                <span>{{ item.fileName }}</span>
              </div>
              <div class="upload-status">
                <span *ngIf="item.isUploading">Uploading file</span>
                <span *ngIf="item.isPending">Waiting for server</span>
                <span *ngIf="item.isProcessing">Preparing analysis</span>
                <span *ngIf="!item.isPending">&nbsp;({{ item.operationProgress | format:FormatType.Percentage }})</span>
              </div>
            </div>
            <button type="button" *ngIf="item.canCancel" class="btn btn-link processing-cancel-button" (click)="cancel(item)">cancel</button>
          </ng-container>

          <ng-container *ngIf="item.isUploaded || item.isSuccessful || item.isError || item.isCancelled || item.isDeleted || item.isDeletedSuccessfully">
            <app-processing-progress-circle [status]="item.status" [totalProgress]="item.totalProgress" [isFatalError]="item.isFatalError"></app-processing-progress-circle>
            <div class="upload-info">
              <div [attr.id]="item.id" class="upload-file">{{ item.fileName }}</div>
            </div>
            <div class="upload-status" *ngIf="item.isCancelled">
              <span>Canceled</span>
            </div>
            <div class="upload-status" *ngIf="item.isDeleted || item.isDeletedSuccessfully">
              <span>Deleted</span>
            </div>
            <shared-info-icon *ngIf="!item.isCancelled && !item.isDeleted && !item.isDeletedSuccessfully"></shared-info-icon>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
