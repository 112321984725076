import { DateHelper } from '../../../helpers/date.helper';
import { DateRange } from '../../../types/date-range.type';

export class CalendarShortcut {
  disabled = false;
  selected = false;

  constructor(public startDayIndex: number, public endDayIndex: number, public label: string) {}

  getSelectionInterval(): DateRange {
    const today = DateHelper.startOfToday();
    const start = DateHelper.subtractDays(today, this.startDayIndex);
    const end = DateHelper.subtractDays(today, this.endDayIndex);
    return new DateRange(start, end);
  }
}
