import { HttpEvent, HttpHandler, HttpRequest, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import { ServerErrorComponent } from 'src/app/layout/_global-errors/server-error/server-error.component';
import { AuthService } from '../services/auth.service';
import { LoggingService } from '../services/logging.service';
import { ModalService } from '../services/modal.service';
import { NavigationService } from '../services/navigation.service';
import { PopupService } from '../services/popup.service';
import { catchError, switchMap, throwError, Observable, Subscription, from } from 'rxjs';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private loggingService: LoggingService,
    private popupService: PopupService,
    private navigationService: NavigationService,
    private modalService: ModalService,
    @Inject(LOCALE_ID) private localeId: string,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.handle(request, next, true);
  }

  private handle(request: HttpRequest<any>, next: HttpHandler, firstTry: boolean): Observable<HttpEvent<any>> {
    const updatedRequest = this.updateRequest(request);
    return next.handle(updatedRequest).pipe(
      catchError(error => {
        if (error.error instanceof ErrorEvent) {
          // client-side error
          const clientError = `Error: ${error.error.message}`;
          this.loggingService.logError(clientError, { error });
        } else if (!navigator.onLine || error?.error?.status === 504 || error?.error?.status === 0) {
          // Handle offline error
          void this.popupService.showInfo('Please check your internet connection.', 'Server not responding', 'Try again').then(() => window.location.reload());
        } else if (error instanceof HttpErrorResponse) {
          const originalError = error;
          switch (originalError.status) {
            case 401:
              if (this.isUrlForLogin(updatedRequest.url) || !firstTry) {
                return this.navigateToLogin(originalError, `API call to "${updatedRequest.url}" returned error 401`, false);
              }

              return from(this.authService.refreshToken()).pipe(
                switchMap(() => this.handle(updatedRequest, next, false)),
                catchError(err => {
                  this.loggingService.logError('Failed to refresh tokens in HTTP interceptor', err);
                  return this.navigateToLogin(originalError, 'Failed to refresh tokens in HTTP interceptor', false);
                }),
              );

            case 500: // Internal Server Error
            case 501: // Not Implemented
            case 502: // Bad Gateway
            case 503: // Service Unavailable
            case 505: // HTTP Version Not Supported
              if (this.isUrlForLogin(updatedRequest.url)) {
                return this.navigateToLogin(originalError, `API call to "${updatedRequest.url}" returned error ${originalError.status}`);
              }

              const errorMsg = `Error Code: ${originalError.status}\nServer Error when obtaining ${originalError.url} from ${location}\nMessage: ${originalError.message}`;
              this.loggingService.logError(errorMsg, originalError);
              this.modalService.open(ServerErrorComponent, d => d);
              return throwError(() => new HttpErrorResponse(originalError));

            default:
              return throwError(() => new HttpErrorResponse(originalError));
          }
        }
        return throwError(() => error);
      }),
    );
  }

  private isUrlForLogin(url: string): boolean {
    const loginUrls = ['/api/login', '/api/login/microsoft', '/api/login/refresh-token', '/api/login/organization'];
    return loginUrls.some(u => url.endsWith(u));
  }

  private navigateToLogin(error: HttpErrorResponse, reason: string, showReason = true, useRedirectUrl = true): Observable<HttpEvent<any>> {
    return from(this.navigationService.navigateToLogin(reason, showReason, useRedirectUrl)).pipe(switchMap(() => throwError(() => error)));
  }

  private updateRequest(request: HttpRequest<any>): HttpRequest<any> {
    let headers = request.headers;

    if (!headers.has('Content-Type')) {
      headers = headers.set('Content-Type', 'application/json');
    }

    // HACK: for uploading files as formData
    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers = headers.delete('Content-Type');
    }

    headers = headers.set('Accept', 'application/json').set('Accept-Language', this.localeId);
    return request.clone({ headers });
  }
}
