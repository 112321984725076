import { Platform } from 'src/app/shared/enums/generated.enums';
import { ProcessingItemError } from './processing-item-error.enum';
import { ProcessingItemStatus } from './processing-item-state.enum';
import { UploadingItem } from './uploading-item.type';

export class ProcessingItem extends UploadingItem {
  masterProcessIds: string[] = [];
  processIds: string[] = [];
  someLogsWereMissing = false;

  get mainMasterProcessId(): string {
    return this.masterProcessIds[0];
  }

  get isPending(): boolean {
    return this.status === ProcessingItemStatus.Pending;
  }

  get isProcessing(): boolean {
    return this.status === ProcessingItemStatus.Processing;
  }

  get isFatalError(): boolean | null {
    return this.status === ProcessingItemStatus.Error ? this.error !== ProcessingItemError.SignalRDisconnected : null;
  }

  override get canCancel(): boolean {
    return this.isUploading || this.isPending || this.isProcessing;
  }

  constructor(uploadId: string, fileName: string, public platform: Platform) {
    super(uploadId, fileName);
  }

  onUploadProcessFinished(masterProcessId: string): void {
    this.masterProcessIds = [masterProcessId];
    this.status = ProcessingItemStatus.Pending;
    this.onProgress(0);
  }

  onProcessAdded(masterProcessIds: string[], processIds: string[], someLogsWereMissing: boolean): void {
    this.masterProcessIds = masterProcessIds;
    this.processIds = processIds;
    this.someLogsWereMissing = someLogsWereMissing;
    this.status = ProcessingItemStatus.Successful;
  }

  protected getTotalProgress(): number {
    switch (this.status) {
      case ProcessingItemStatus.Uploading:
        return this.operationProgress / 4;
      case ProcessingItemStatus.Pending:
        return 0.25;
      case ProcessingItemStatus.Processing:
        return 0.25 + (this.operationProgress * 3) / 4;
      default:
        return 0;
    }
  }
}
