import { Injectable } from '@angular/core';
import { Marking } from '../enums/generated.enums';
import { MapHelper } from '../helpers/map.helper';
import { Data } from '../types/data.type';
import { Edge } from '../types/edge.type';
import { Node } from '../types/node.type';
import { Variant } from '../types/variant.type';
import { NavigationService } from './navigation.service';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class VariantsSelectionService {
  variants: Variant[] = [];
  selectedVariantsIds: string[] = [];
  private allVariantsCount: number;
  private allVariantsCasesCount: number;
  private _currentVariant: Variant;

  totalCasesCount: number;
  casesCount: number;
  logsCount: number;
  casesCountPercentage: number;
  allVariantsSelected: boolean;
  selectedVariantsAreExceptionsOnly: boolean;

  get selectedVariants(): Variant[] {
    return this.variants.filter(v => v.isSelected);
  }

  get currentVariant(): Variant {
    return this._currentVariant;
  }

  set currentVariant(currentVariant: Variant) {
    this._currentVariant = currentVariant;
    this.storageService.currentVariantId = currentVariant?.id;
  }

  constructor(private data: Data, private storageService: StorageService, private navigationService: NavigationService) {}

  async initialize(predicate?: (variant: Variant) => boolean): Promise<void> {
    this.variants = MapHelper.toArray(this.data.variants);
    this.variants.sort((x, y) => x.sortOrder - y.sortOrder);
    this.allVariantsCount = this.variants.length;
    this.allVariantsCasesCount = this.variants.reduce((a, b) => a + b.statistics.casesCount, 0);
    this._currentVariant = this.storageService.currentVariantId ? this.data.variants.get(this.storageService.currentVariantId) : null;
    if (predicate) {
      await this.selectVariants(predicate, true, false);
    } else if (this.storageService.selectedVariantIds) {
      await this.selectVariants(v => this.storageService.selectedVariantIds.includes(v.id), false, false);
    } else {
      await this.selectVariants(v => v.statistics.casesCountPercentage > 0.01, false, false);
    }
  }

  async toggleVariant(variant: Variant): Promise<void> {
    await this.selectVariants(v => (v.id === variant.id ? !v.isSelected : v.isSelected));
  }

  async toggleMultipleVariant(variantsIds: string[], isSelected: boolean): Promise<void> {
    await this.selectVariants(v => (variantsIds.indexOf(v.id) > -1 ? isSelected : v.isSelected));
  }

  async selectAllVariants(isSelected: boolean): Promise<void> {
    await this.selectVariants(() => isSelected);
  }

  async selectOnlyVariant(variantId: string): Promise<void> {
    await this.selectVariants(v => v.id === variantId);
  }

  async selectVariantsByItem(item: Node | Edge): Promise<void> {
    await this.selectVariants(v => this.variantsContainsItem(v.nodeIds, v.edgeIds, item));
  }

  private variantsContainsItem(nodeIds: number[], edgeIds: number[], item: Node | Edge): boolean {
    if (item instanceof Node) {
      return item.isStartEnd || nodeIds.includes(item.id);
    }

    if (item instanceof Edge && (item.isStartEnd || item.startNode?.isStartEnd || item.endNode?.isStartEnd)) {
      return true;
    }

    if (item.isStartEnd) {
      return (item.startNode.isStartEnd && nodeIds[0] === item.to) || (item.endNode.isStartEnd && nodeIds[nodeIds.length - 1] === item.from);
    }

    const nodeIdSet = new Set(nodeIds);
    return new Set(edgeIds).has(item.id) && nodeIdSet.has(item.from) && nodeIdSet.has(item.to);
  }

  async selectVariants(predicate: (variant: Variant) => boolean, save = true, removeQueryParameters = true): Promise<void> {
    let changed = false;
    this.variants.forEach(v => {
      const isSelected = predicate(v);
      changed = changed || v.isSelected !== isSelected;
      v.isSelected = isSelected;
    });

    if (changed) {
      await this.refresh(save, removeQueryParameters);
    }
  }

  private async refresh(save: boolean, removeQueryParameters: boolean) {
    // visible nodes | edges
    this.casesCount = this.selectedVariants.reduce((a, b) => a + b.statistics.casesCount, 0);
    this.casesCountPercentage = this.casesCount / this.allVariantsCasesCount;
    this.logsCount = this.selectedVariants.reduce((a, b) => a + b.statistics.logsCount, 0);
    this.allVariantsSelected = this.selectedVariants.length === this.allVariantsCount;
    this.selectedVariantsAreExceptionsOnly = !this.selectedVariants.some(v => v.marking === Marking.Success);

    if (save) {
      this.selectedVariantsIds = this.selectedVariants.map(v => v.id);
      this.storageService.selectedVariantIds = this.selectedVariantsIds;
    }

    if (removeQueryParameters) {
      await this.navigationService.clearQueryParams();
    }

    await this.data.refreshSelection();
  }
}
